'use client';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Image from 'next/image';
import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import Button from '../../Button';
import FutureEventCountdown from './FutureEventCountDown';
import messages from './messages';
import { useOnClickOutside } from '../../../hooks/useOutsideClick';
import Icon from '../../Icon';
import { CalendarOption } from './CalendarOption';
import { AddToCalendarEvent } from '../../../utils/add-to-calendar';
import { ContentfulImage } from '@/types/contentful';
import { CalendarOptions } from '@/types/calendar';
import { useEventListener } from '@/utils/useEventListener.web';
type FutureEventWithTicketProps = {
  doorsOpenDate?: Date;
  image?: ContentfulImage;
  selectedVariantSku?: string;
  title: string;
  description?: string;
  eventStart?: Date;
  duration?: number;
};
const FutureEventWithTicket: FC<FutureEventWithTicketProps> = ({
  doorsOpenDate,
  selectedVariantSku,
  image,
  eventStart,
  title: eventTitle,
  description,
  duration
}) => {
  const {
    bought_event_countdown: {
      title,
      subtitle
    }
  } = messages;
  const ref = useRef(null);
  const [showCalendarDropdown, setShowCalendarDropdown] = useState(false);
  useOnClickOutside(ref, () => setShowCalendarDropdown(false));
  const eventInfoImage = image && image?.asset?.file?.url;
  const calendarOptions: CalendarOptions[] = ['Apple', 'Google', 'Office 365', 'Outlook', 'Outlook.com', 'Yahoo'];
  const config: AddToCalendarEvent = {
    title: eventTitle,
    description,
    location: document?.URL ?? 'https://onair.events',
    startTime: eventStart,
    duration,
    sku: selectedVariantSku
  };
  const handleEscape = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setShowCalendarDropdown(false);
    }
  }, []);
  useEventListener('keydown', handleEscape);
  return <div className="upcoming-event-countdown hero__content align-items-center" data-sentry-component="FutureEventWithTicket" data-sentry-source-file="FutureEventWithTicket.tsx">
            {eventInfoImage && <Image fill src={eventInfoImage} alt={image?.alt || ''} className="hero__event-image mx-auto" objectFit="contain" />}

            <div>
                <p className="mt-4 mb-2 mb-md-3 subtitle">{title}</p>
                {doorsOpenDate && <FutureEventCountdown startDate={doorsOpenDate} />}
                <p className="mt-2 mt-md-3 body-text">{subtitle}</p>
                <div className="d-flex justify-content-center align-items-center mt-2 mt-md-3">
                    <Button icon="oa-live" disabled className="btn-onair btn-watch-live-countdown-bt" data-sentry-element="Button" data-sentry-source-file="FutureEventWithTicket.tsx">
                        {messages.bought_event_countdown.watch_live}
                    </Button>

                    {eventStart && <div className="add-to-calendar d-flex align-items-center justify-content-center">
                            <button type="button" onClick={() => setShowCalendarDropdown(true)} className="add-to-calendar__button d-flex align-items-center justify-content-center">
                                <Icon icon="add-to-calendar__icon oa-calendar" />
                            </button>
                        </div>}
                </div>
            </div>

            {/* Add to calendar dropdown */}
            <div ref={ref} className={classNames('add-to-calendar__dropdown flex-column align-items-start', {
      'd-none': !showCalendarDropdown
    })}>
                {calendarOptions.map((option: CalendarOptions): ReactNode => <CalendarOption option={option} config={config} sku={selectedVariantSku} />)}
            </div>
        </div>;
};
export default FutureEventWithTicket;