import { isValid } from 'date-fns';
import { template } from 'lodash';
import { isOlderThanThirteen } from '../../utils/date';
import messages from './messages';
import { serializeForm } from '../../utils/form';
import { getDateFormatted } from '../date-input/date-input-utils';
import {
    PreSerializedRegisterFormData,
    SerializedRegisterFormData,
} from './RegisterTypes';
import { LoginReason } from './LoginScreenTypes';
import { FieldError } from 'react-hook-form';
import { useAppSelector } from '@/state/hooks';
import { NavigationState } from '@/state/reducers/navigationSlice';
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from '@/errors';
import { commonTexts } from '@/messages/common';

export const getRegisterSerializedFormatData = (
    form: HTMLFormElement,
): SerializedRegisterFormData => {
    const formElement: HTMLFormElement = form;
    const preSerializedData = serializeForm(
        formElement,
    ) as PreSerializedRegisterFormData;
    const { day, month, year } = preSerializedData;
    return {
        ...preSerializedData,
        acceptsMarketing: preSerializedData?.acceptsMarketing === 'on',
        acceptsTermsAndConditions:
            preSerializedData.acceptsTermsAndConditions === 'on',
        dateOfBirth: getDateFormatted(day, month, year),
    };
};

export const invalidDateOfBirthMessage = template(
    VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid],
)({ field: commonTexts.date_of_birth });

export const requiredDateOfBirthMessage = template(
    VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required],
)({ field: commonTexts.date_of_birth });

export const getRegisterYearValidationRules = (birthDate?: string) => ({
    min: 1900,
    required: true,
    validate: {
        olderThanThirteen: () => {
            if (birthDate && isValid(new Date(birthDate))) {
                return isOlderThanThirteen(birthDate);
            }
            /* this validation rule should focus only on validating if the user is older than 13. Other
            validations like minimum date or required are responsibility of the other validation rules */
            return true;
        },
    },
});

export type RegisterDateValidationType =
    | 'min'
    | 'max'
    | 'required'
    | 'olderThanThirteen';

export const getDateOfBirthErrorMessage = (
    // TO DO: Remove the any here, but it was a very random error.
    validationType: RegisterDateValidationType | any,
) => {
    if (!validationType) return invalidDateOfBirthMessage;

    switch (validationType) {
        case 'required':
            return requiredDateOfBirthMessage;
        case 'olderThanThirteen':
            return messages.register.error_minimum_age_required;
        case 'min':
            return '';
        case 'max':
            return '';
        default:
            return invalidDateOfBirthMessage;
    }
};

/**
 *  A function extracting abd returning loginReason and reason from url params
 * @returns params reason or loginReason
 */
export const getLoginReason = (navigation: NavigationState): LoginReason => {
    
    // First check window's history state in case it was an
    // internal redirect
    if (navigation.loginReason) {
        return navigation.loginReason;
    }
    // Then check if the window has reason param populated
    // by an external link like the "Delete account" QR
    if (window?.location?.search) {
        const urlParams = new URLSearchParams(window?.location?.search);
        // Extract login reason from params
        return urlParams?.get('reason')?.toString() as LoginReason;
    }
    // In case no params are populated return DEFAULT as reason
    return 'DEFAULT';
};
