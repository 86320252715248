'use client';

/* eslint-disable no-underscore-dangle */
import React from 'react';
import GalleryComponent from '../../gallery/GalleryComponent';
import EventContentBlock from '../../event-content-block';
import VideoBlock from '../../video-block';
import useEventIcons from '../../../hooks/useVariantIconsPick';
import SellingPointsSectionComponent from '../../selling-point/SellingPointsSectionComponent';
import { SpotifyPlayerBlock } from '../../spotify-player';
import { HighlightsBlock } from '../../highlights-block';
import { useAddAnalyticsToEventLinks } from '../../../hooks/useAddAnalyticsToEventLinks';
import type { FC } from 'react';
import EventCarousel from '@/components/event-carousel';
import { EventsByTag } from '@/types/page-context';
import { EventBlock, Event } from '@/types/contentful';
type Props = {
  block: EventBlock;
  event: Event;
  eventsByTag: EventsByTag;
  autoPlayVideo?: boolean;
  showTransparentBackground?: boolean;
  isFirstBlock: boolean;
};
const EventBlockComponent: FC<Props> = ({
  block,
  event,
  autoPlayVideo,
  showTransparentBackground,
  isFirstBlock
}) => {
  const contentBlockIcons = useEventIcons(event);

  // Support contentBlock links, the check is internally in the hook
  // so that it will not be rendered conditionally
  const blockLinks = useAddAnalyticsToEventLinks({
    block,
    event
  });
  //TODO: zak
  if (Object.values(block).length < 2) return;
  return <>
            {block.__type === 'contentBlock' && <EventContentBlock {...block}
    //TODO
    //@ts-ignore
    event={event} icons={block.type === 'EventDescription' ? contentBlockIcons : undefined} links={blockLinks} isFirstBlock={isFirstBlock} />}

            {block.__type === 'sellingPointsSection' && block.sellingPoints?.length && <SellingPointsSectionComponent sellingPoints={block.sellingPoints} showTitle={block.showTitle} isFirstBlock={isFirstBlock} />}

            {block.__type === 'galleryBlock' && block.images?.length && <div className="container container--vertical-padding">
                    <GalleryComponent images={block.images} preserveAspectRatio={block.preserveAspectRatio} isFirstBlock={isFirstBlock} />
                </div>}
            {block.__type === 'eventCarouselBlock' && <EventCarousel carousel={block} isFirstBlock={isFirstBlock} />}
            {block.__type === 'videoBlock' && <VideoBlock youtubeLink={block.youtubeLink} autoPlay={autoPlayVideo} selfHostedVideo={block.selfHostedVideo} isFirstBlock={isFirstBlock} />}

            {block.__type === 'spotifyPlaylist' && <SpotifyPlayerBlock
    // @ts-ignore
    embeddedPlaylist={block.embeddedPlaylist} callToActionButton={block.callToActionButton} title={block?.title} text={block?.text} isFirstBlock={isFirstBlock} />}

            {block.__type === 'highlightsBlock' && <HighlightsBlock highlights={block.highlights} showTransparentBackground={showTransparentBackground} isFirstBlock={isFirstBlock} />}
        </>;
};
export default EventBlockComponent;