'use client';

import React, { useEffect } from 'react';
import PrivateRoute from '../../PrivateRoute';
import MyPlace from '../../my-place/MyPlace';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';
const MyPlacePage = ({
  location,
  pageContext
}: {
  location?: Location;
  pageContext: any;
}) => {
  useEffect(() => {
    logPageViewToSegment(`/${pageContext.slug}`);
  }, []);
  return <Shell isFooterTopLineDisabled pathname={location?.pathname} ogp={pageContext?.seo} data-sentry-element="Shell" data-sentry-component="MyPlacePage" data-sentry-source-file="index.tsx">
            <div className="container" style={{
      paddingTop: 90,
      marginBottom: 200
    }}>
                <MyPlace data-sentry-element="MyPlace" data-sentry-source-file="index.tsx" />
            </div>
        </Shell>;
};
//@ts-ignore
export default ({
  pageContext
}: {
  pageContext: any;
}) =>
//@ts-ignore
<PrivateRoute component={MyPlacePage} pageContext={pageContext} />;