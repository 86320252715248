'use client';

import React, { useEffect, useState } from 'react';
import EventBlockComponent from './EventBlockComponent';
import OnlyForSaleWithPartner from './OnlyForSaleWithPartner';
import Shell from '../../Shell';
import Hero from '../../hero/HeroComponent';
import { MessageBanner } from '../../banner';
import type { CodedError } from '../../../types/error';
import type { THEOplayerError } from '../../theo-player/types';
import type { EventsByTag, OGP } from '../../../types/page-context';
import { ContentfulImage, Event, EventBlock, EventStatus } from '@/types/contentful';
import type { FC } from 'react';
import HeroWithGeoIpAccess from './HeroWithGeoIpAccess';
import NotForSale from './NotForSale';
import EventCarousel from '@/components/event-carousel';
import { EventPartner } from '@/types/contentful/event';
import { PlayerEventAction } from '@/types/Segment';
interface Props {
  event: Event;
  eventsByTag: EventsByTag;
  selectedVariantSku?: string;
  isShowRestrictedInCurrentCountry?: boolean;
  partnerToAccessShowInCurrentCountry?: EventPartner;
  // Player and Hero related properties
  eventStatus?: EventStatus;
  doorsOpenDate?: Date;
  eventStart?: Date;
  duration?: number;
  isSaleOpen?: boolean;
  showCountdownBanner?: boolean;
  countDownBannerInfo?: {
    playingNow: boolean;
    countDownTime: Date;
  };
  showEventConfigurator?: boolean;
  showPlayer?: boolean;
  showPlayerLoader?: boolean;
  showError?: boolean;
  showPlaceholder?: boolean;
  onPlayerError?: (error: THEOplayerError) => void;
  logTrackPlayEvent?: (duration: number) => void;
  trackPlayerEvents?: (trackAction: PlayerEventAction) => void;
  error?: CodedError;
  hasTicketInShopify?: boolean;
  hasAccess?: boolean;
  loggedIn?: boolean;
  isWatchTrailerParamSet?: boolean;
  blocks?: EventBlock[];
  stream?: {
    dashUrl?: string;
    hlsUrl?: string;
    dashCastUrl?: string;
  };
  ogp?: OGP;
  schemaData?: {
    prerendered?: string;
  };
  backgroundImageProps: any;
  trackingTitle?: string;
  shopifyUserId?: string;
  streamUrlsError?: boolean;
  location?: Location;
  jwt?: string;
}
const EventPageComponent: FC<Props> = ({
  event,
  eventsByTag,
  selectedVariantSku,
  isShowRestrictedInCurrentCountry,
  partnerToAccessShowInCurrentCountry,
  isWatchTrailerParamSet,
  blocks,
  duration,
  showPlaceholder,
  loggedIn = false,
  showCountdownBanner = false,
  countDownBannerInfo,
  showPlayer = false,
  showEventConfigurator = false,
  showPlayerLoader = false,
  showError = false,
  error,
  onPlayerError,
  logTrackPlayEvent,
  trackPlayerEvents,
  hasTicketInShopify = false,
  hasAccess = false,
  isSaleOpen = false,
  eventStatus,
  doorsOpenDate,
  eventStart,
  stream,
  ogp,
  schemaData,
  backgroundImageProps,
  trackingTitle,
  shopifyUserId,
  streamUrlsError,
  location,
  jwt
}) => {
  // This logic makes sure we don't get hydration errors
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    setIsLoggedIn(loggedIn);
  }, [loggedIn]);
  return <>
            <Shell isFooterTopLineDisabled ogp={ogp} pathname={location?.pathname} schemaData={schemaData} banner={showCountdownBanner && countDownBannerInfo && <MessageBanner type="EventCountdown" startDate={countDownBannerInfo.countDownTime} playingNow={countDownBannerInfo.playingNow} />} data-sentry-element="Shell" data-sentry-source-file="EventPageComponent.tsx">
                {isShowRestrictedInCurrentCountry ? <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isCentered isEventHero>
                        {!partnerToAccessShowInCurrentCountry?.partnerName && !partnerToAccessShowInCurrentCountry?.partnerLink ? <NotForSale image={event.eventInfoLogo as ContentfulImage} /> : <OnlyForSaleWithPartner image={event.eventInfoLogo as ContentfulImage} partnerName={partnerToAccessShowInCurrentCountry?.partnerName} partnerLink={partnerToAccessShowInCurrentCountry?.partnerLink} />}
                    </Hero> : <HeroWithGeoIpAccess event={event} selectedVariantSku={selectedVariantSku} isShowRestrictedInCurrentCountry={isShowRestrictedInCurrentCountry} partnerToAccessShowInCurrentCountry={partnerToAccessShowInCurrentCountry} eventStatus={eventStatus} doorsOpenDate={doorsOpenDate} eventStart={eventStart} duration={duration} isSaleOpen={isSaleOpen} showEventConfigurator={showEventConfigurator} showPlayer={showPlayer} showPlayerLoader={showPlayerLoader} showError={showError} showPlaceholder={showPlaceholder} onPlayerError={onPlayerError} logTrackPlayEvent={logTrackPlayEvent} trackPlayerEvents={trackPlayerEvents} error={error} hasTicketInShopify={hasTicketInShopify} hasAccess={hasAccess} loggedIn={loggedIn} stream={stream} backgroundImageProps={backgroundImageProps} trackingTitle={trackingTitle} shopifyUserId={shopifyUserId} streamUrlsError={streamUrlsError} jwt={jwt} />}

                {blocks?.map((block, index) => <EventBlockComponent key={`block${index}`} isFirstBlock={index === 0 && !showPlayer} block={block} event={event} eventsByTag={eventsByTag} showTransparentBackground={blocks.length > 1 || index % 2 !== 0} autoPlayVideo={isWatchTrailerParamSet && blocks.find(({
        __type
      }) => __type === 'videoBlock') === block} />)}

                {event.relatedShows && <EventCarousel carousel={event.relatedShows} />}
            </Shell>
        </>;
};
export default EventPageComponent;