"use client";

import React, { FC } from 'react';
import useRedirectIfLoggedIn from '../../hooks/useRedirectIfLoggedIn';
import ROUTES from '../../constants/routes';
import CheckYourEmailComponent from './component';
const CheckYourEmail: FC = () => {
  useRedirectIfLoggedIn(ROUTES.myPlace);
  return <CheckYourEmailComponent data-sentry-element="CheckYourEmailComponent" data-sentry-component="CheckYourEmail" data-sentry-source-file="index.tsx" />;
};
export default CheckYourEmail;