import React from 'react';
import { template } from 'lodash';
import * as EmailValidator from 'email-validator';
import Button from '../../../Button';
import Input from '../../../input';
import OnAirNotification from '../../../OnAirNotification';
import myPlaceMessages from '../../messages';
import MyPlaceTitle from '../../MyPlaceTitle';
import { goToRoute } from '../../../../utils/navigation';
import ROUTES from '../../../../constants/routes';
import type { FieldValues, UseFormRegister } from 'react-hook-form';
import type { FC } from 'react';
import LinkInternal from '@/components/LinkInternal';
import { commonTexts } from '@/messages/common';
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from '@/errors';
import { ButtonType } from '@/enums/ButtonType';
import { NotificationObject } from '@/types/Notification';
interface ChangeEmailComponentProps {
  readonly notification?: NotificationObject;
  readonly onChangeForm: () => void;
  readonly formRef: React.RefObject<HTMLFormElement>;
  readonly validationErrors: Record<string, any>;
  readonly onSubmitForm: () => void;
  readonly registerInput: UseFormRegister<FieldValues>;
  readonly isLoading?: boolean;
}
const ChangeEmailComponent: FC<ChangeEmailComponentProps> = ({
  onSubmitForm,
  onChangeForm,
  formRef,
  validationErrors,
  registerInput,
  notification,
  isLoading
}) => <div data-sentry-component="ChangeEmailComponent" data-sentry-source-file="ChangeEmailComponent.tsx">
        <MyPlaceTitle data-sentry-element="MyPlaceTitle" data-sentry-source-file="ChangeEmailComponent.tsx">
            {myPlaceMessages.profile_settings.change_email.title}
        </MyPlaceTitle>
        {notification?.text && <OnAirNotification className="mb-3" text={notification.text} type={notification.type} />}
        <form autoComplete="off" onChange={onChangeForm} ref={formRef} onSubmit={onSubmitForm} className="m-0">
            <Input className="mb-2" onEnter={onSubmitForm} label={commonTexts.email_address} icon="oa-email" autoGeneratedProps={registerInput('email', {
      required: {
        value: true,
        message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail])({
          field: commonTexts.email_address
        })
      },
      validate: {
        validEmail: (value: string) => EmailValidator.validate(value) || VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail]
      }
    })} error={validationErrors.email?.message} testId="emailInput" data-sentry-element="Input" data-sentry-source-file="ChangeEmailComponent.tsx" />

            <Input className="mb-2" onEnter={onSubmitForm} label={commonTexts.confirm_email_address} icon="oa-email" autoGeneratedProps={registerInput('confirmEmail', {
      required: {
        value: true,
        message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail])({
          field: commonTexts.confirm_email_address
        })
      },
      validate: {
        validEmail: (value: string) => EmailValidator.validate(value) || VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail]
      }
    })} error={validationErrors.confirmEmail?.message} testId="confirmEmailInput" data-sentry-element="Input" data-sentry-source-file="ChangeEmailComponent.tsx" />

            <Input onEnter={onSubmitForm} label={commonTexts.current_password} type="password" icon="oa-lock" autoGeneratedProps={registerInput('password', {
      required: {
        value: true,
        message: template(VALIDATION_ERROR_MESSAGES[`${ValidationErrorCode.required}-personal-approach`])({
          field: commonTexts.current_password
        })
      }
    })} error={validationErrors.password?.message} testId="passwordInput" data-sentry-element="Input" data-sentry-source-file="ChangeEmailComponent.tsx" />

            <Button className="w-100 mt-3" onClick={onSubmitForm} testId="submitButton" loading={isLoading} data-sentry-element="Button" data-sentry-source-file="ChangeEmailComponent.tsx">
                {myPlaceMessages.profile_settings.change_email.primary_cta}
            </Button>

            <LinkInternal to={`${ROUTES.myPlace}${ROUTES.profileSettings}`} data-sentry-element="LinkInternal" data-sentry-source-file="ChangeEmailComponent.tsx">
                <Button className="w-100 mt-2" type={ButtonType.OUTLINE_SECONDARY} data-sentry-element="Button" data-sentry-source-file="ChangeEmailComponent.tsx">
                    {myPlaceMessages.profile_settings.change_email.secondary_cta}
                </Button>
            </LinkInternal>
        </form>
    </div>;
export default ChangeEmailComponent;