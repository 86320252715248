'use client';

// @ts-ignore
import { motion } from 'framer-motion';
import Hls from 'hls.js';
import React, { useEffect, useRef } from 'react';
interface VideoProps {
  videoUrl: string;
  loop?: boolean;
  muted?: boolean;
  autoPlay?: boolean;
  delay?: number;
  duration?: number;
}
const HlsStreamPlayer = ({
  videoUrl,
  loop = true,
  muted = true,
  autoPlay = true,
  delay = 1,
  duration = 1
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);
  useEffect(() => {
    const initPlayer = () => {
      if (videoRef.current) {
        const video = videoRef.current;
        if (Hls.isSupported()) {
          const hls = new Hls();
          hlsRef.current = hls;
          hls.loadSource(videoUrl);
          hls.attachMedia(video);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (autoPlay) {
              setTimeout(() => video.play(), delay * 1000);
            }
          });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = videoUrl;
          video.addEventListener('loadedmetadata', () => {
            if (autoPlay) {
              setTimeout(() => video.play(), delay * 1000);
            }
          });
        }
      }
    };
    initPlayer();
    return () => {
      if (hlsRef.current) hlsRef.current.destroy();
    };
  }, [videoUrl]);
  return <motion.video ref={videoRef} muted={muted} playsInline={true} autoPlay={autoPlay} loop={loop} initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} transition={{
    duration: duration,
    delay: delay
  }} className="hero-wrapper__video" data-sentry-element="unknown" data-sentry-component="HlsStreamPlayer" data-sentry-source-file="StreamHlsPlayer.tsx" />;
};
export default HlsStreamPlayer;