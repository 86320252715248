import React from 'react';
import Skeleton from 'react-loading-skeleton';
import SkeletonTheme from '../../SkeletonTheme';
export const TicketSkeleton = () => <div className="ticket ticket-skeleton mb-4" data-sentry-component="TicketSkeleton" data-sentry-source-file="MyTicketsSkeleton.tsx">
        <Skeleton borderRadius="10px" className="ticket__image me-2" data-sentry-element="Skeleton" data-sentry-source-file="MyTicketsSkeleton.tsx" />

        <div className="d-flex flex-column w-100">
            <Skeleton height="32px" className="mb-1 ticket-skeleton__title" data-sentry-element="Skeleton" data-sentry-source-file="MyTicketsSkeleton.tsx" />
            <Skeleton height="24px" className="mb-1 ticket-skeleton__subtitle" data-sentry-element="Skeleton" data-sentry-source-file="MyTicketsSkeleton.tsx" />
            <div className="h-100 d-none d-lg-block">
                <Skeleton height="100%" data-sentry-element="Skeleton" data-sentry-source-file="MyTicketsSkeleton.tsx" />
            </div>
        </div>
    </div>;
const MyTicketsSkeleton = () => <>
        <SkeletonTheme data-sentry-element="SkeletonTheme" data-sentry-source-file="MyTicketsSkeleton.tsx">
            <TicketSkeleton data-sentry-element="TicketSkeleton" data-sentry-source-file="MyTicketsSkeleton.tsx" />
            <TicketSkeleton data-sentry-element="TicketSkeleton" data-sentry-source-file="MyTicketsSkeleton.tsx" />
        </SkeletonTheme>
    </>;
export default MyTicketsSkeleton;