import React, { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import InputError from './input/InputError';
export interface TextFieldProps {
  placeholder?: string;
  label?: string;
  error?: string | boolean;
  type?: string;
  icon?: string;
  className?: string;
  link?: {
    to: string;
    text: string;
  };
  autoComplete?: string;

  // Functional props
  value?: string;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onEnter?: () => void;

  // Boolean props
  optional?: boolean;
  disabled?: boolean;
  validated?: boolean;
  grow?: boolean;
  noPadding?: boolean;
  smallLabel?: boolean;
  rowSize?: number;

  // Number props
  maxLength?: number;

  // Test props
  testId?: string;

  // TODO update all inputs to use the autoGeneratedProps, then it's easier
  // to set the name, onChange, validation, etc.
  // autoGeneratedProps?: UseFormRegisterReturn;
  autoGeneratedProps?: any;
}
export const TextField: FC<TextFieldProps> = ({
  placeholder,
  disabled,
  error,
  label,
  smallLabel,
  rowSize,
  validated,
  value,
  defaultValue,
  onChange,
  type = 'text',
  maxLength,
  grow,
  className,
  icon,
  onEnter,
  noPadding,
  testId,
  autoGeneratedProps,
  autoComplete
}) => {
  const onChangeInternal = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(event);
  };
  return <div className={classNames('onair-input__wrapper', {
    'onair-input--with-icon': icon,
    'onair-input--disabled': disabled,
    'onair-input--error': error,
    'onair-input--validated': validated,
    'onair-input--grow': grow,
    'onair-input--password': type === 'password',
    'onair-input--no-padding': noPadding
  }, className)} data-sentry-component="TextField" data-sentry-source-file="TextField.tsx">
            {label && <p className={classNames('onair-input__label-wrap body-text d-flex', {
      'caption-text': smallLabel
    })}>
                    <span className="onair-input__label">{label}</span>
                </p>}
            <div className="onair-input__field-container">
                <textarea placeholder={placeholder || undefined} value={value} defaultValue={defaultValue} onChange={event => onChangeInternal(event)} maxLength={maxLength} onKeyDown={e => {
        if (e.key === 'Enter' && onEnter) onEnter();
      }} data-testid={testId} {...autoGeneratedProps || {}} autoComplete={autoComplete} rows={rowSize} resize="none" />
            </div>
            {typeof error === 'string' && error && <InputError testId={testId ? `${testId}Error` : ''}>
                    {error}
                </InputError>}
        </div>;
};
export default TextField;