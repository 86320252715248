/* eslint-disable import/no-unresolved */
/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';


import { algoliaSearchServerSide } from '@/utils/server-functions/algoliaSearchServerSide';
import { contentfulEventsBySlugsServerSide } from '@/utils/server-functions/contentfulEventsBySlugsServerSide';
import { ContentfulImage } from '../types/contentful';

interface UseAlgoliaSearchProps {
    query: string;
}

export interface AlgoliaResult {
    objectID: string;
    fields: {
        slug: {
            'en-US': string;
        };
    };
}

interface Result {
    carouselLogo?: ContentfulImage;
    eventInfoLogo?: ContentfulImage;
    portraitPoster?: ContentfulImage;
    poster: ContentfulImage;
    shortTitle?: string;
    slug: string;
    sys: {
        id: string;
    };
    title: string;
}

export const useAlgoliaSearch = ({ query }: UseAlgoliaSearchProps) => {
    const [algoliaResults, setAlgoliaResults] = useState<AlgoliaResult[]>([]);
    const [results, setResults] = useState<Result[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any | null>(null);

    const reset = () => {
        setResults([]);
        setError(null);
    };

    useEffect(() => {
        if (query === '') {
            reset();
            return undefined;
        }
        const search = async () => {
            setIsLoading(true);
            try {
                const hits = await algoliaSearchServerSide({ query });

                setAlgoliaResults(hits);
                if (hits?.length === 0) setError('No results');
            } catch (_error) {
                setError(_error);
            } finally {
                setIsLoading(false);
            }
        };
        const delayDebounceFn = setTimeout(() => {
            search();
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [query]);

    useEffect(() => {
        const fetchContentfulData = async () => {
            const responseData = await contentfulEventsBySlugsServerSide({
                algoliaResults,
            });

            setResults(responseData);
        };
        fetchContentfulData();
    }, [algoliaResults]);

    return { results, isLoading, error };
};
