import { ContentfulEventMerchandise } from "../types/contentful/index";


/**
 *
 * @description A helper function used to filter the allowed merchandise for each country
 *
 * @param customerCountryCode User's country code eg: NL, US
 * @param merchandise Event's merchandise
 * @returns A filtered array of the allowed merchandises for each country
 */
export const filterEventMerchandiseByCountry = (
    customerCountryCode: string,
    merchandise: ContentfulEventMerchandise[],
): ContentfulEventMerchandise[] => {
    const allowedMerchandise: ContentfulEventMerchandise[] = [];

    for (const item of merchandise) {
        // Check if the item has whitelist pop populated
        if (item.whitelistedCountryGroups?.length) {
            // Loop on every whitelist country group and check if one country matches with user's country
            const isWhitelisted = item.whitelistedCountryGroups.some((group) =>
                group.countries.includes(customerCountryCode),
            );

            if (isWhitelisted) allowedMerchandise.push(item);
        } else {
            // If no whitelist country is given show the item globally
            allowedMerchandise.push(item);
        }
    }
    return allowedMerchandise;
};
