/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable unicorn/filename-case */

import fetch from './fetch';

import type { SerializedError } from '@reduxjs/toolkit';
import { getEnvironmentVariableServerSide } from './server-functions/getEnvServerSide';

export interface AuthXMLResponse {
    data: {
        hasAccess: boolean;
        authXML: string;
        foundInCache: boolean;
    };
    error?: string;
}

export type AuthXMLErrorCode = 'UNEXPECTED' | 'ERROR_RESPONSE';

export class AuthXMLError extends Error implements SerializedError {
    readonly code: AuthXMLErrorCode;

    constructor(code: AuthXMLErrorCode, message: string) {
        super(message);
        this.code = code;
        this.name = 'AuthXMLError';
    }
}

export async function fetchAuthXML({ sku, jwt }: { sku: string; jwt: string }) {
    const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
        'ONAIR_ENDPOINT',
    ]);
    const response = await fetch(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${ONAIR_ENDPOINT}/generate-auth-xml`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sku, jwt }),
        },
    );

    if (!response.ok)
        throw new AuthXMLError(
            'UNEXPECTED',
            'Unexpected error has occured, please try again later...',
        );

    const { error, data } = (await response.json()) as AuthXMLResponse;

    if (error) throw new AuthXMLError('ERROR_RESPONSE', error);

    return data;
}
