/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable unicorn/no-keyword-prefix */
'use client';

import React, { useState } from 'react';
import classNames from 'classnames';
import Shell from '../../Shell';
import SearchInput from '../../search/SearchInput';
import { useAlgoliaSearch } from '../../../hooks/useAlgoliaSearch';
import SearchResults from '../../search/SearchResults';
import SearchError from '../../search/SearchError';
import EventCarousel from '../../event-carousel';
import type { EventsByTag } from '@/types/page-context';
import { ContentfulEventCarousel } from '@/types/contentful';
interface SearchPageComponentProps {
  carousels?: ContentfulEventCarousel[];
  location?: Location;
  eventsByTag?: EventsByTag;
}
export const SearchPageComponent = ({
  location,
  carousels,
  eventsByTag
}: SearchPageComponentProps) => {
  const [query, setQuery] = useState('');
  const {
    results,
    error
  } = useAlgoliaSearch({
    query
  });
  const onSearch = (q: string) => {
    setQuery(q);
  };
  return <Shell isFooterTopLineDisabled pathname={location?.pathname} data-sentry-element="Shell" data-sentry-component="SearchPageComponent" data-sentry-source-file="SearchComponent.tsx">
            <div className={classNames('search-page mt-7 mb-5')}>
                <div className="container">
                    <SearchInput onSearch={onSearch} query={query} onClear={() => setQuery('')} data-sentry-element="SearchInput" data-sentry-source-file="SearchComponent.tsx" />
                </div>
                {results?.length === 0 ? <div>
                        {results?.length === 0 && error && <div className="mt-4">
                                <SearchError query={query} />
                            </div>}
                        {results?.length === 0 && !error && carousels && carousels.map(carousel => <EventCarousel key={carousel.title} carousel={carousel} small />)}
                    </div> : <SearchResults results={results} />}
            </div>
        </Shell>;
};