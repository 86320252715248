import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import type { FC } from "react";
import Button from "../Button";
import { ButtonType } from "@/enums/ButtonType";
const SearchInput: FC<{
  onSearch: any;
  onClear: any;
  query: string;
}> = ({
  onSearch,
  query,
  onClear
}) => <form className={classNames("search-wrapper d-flex col-md-6 col-12")} onSubmit={e => e.preventDefault()} data-sentry-component="SearchInput" data-sentry-source-file="SearchInput.tsx">
		<div className="navbar__search">
			<div className="search-wrapper__search-addon" />
			<input autoFocus onChange={e => onSearch(e.target.value)} value={query} />
			{query && <Button className="search-wrapper__search-clear" type={ButtonType.OUTLINE_SECONDARY} onClick={onClear} icon="oa-close" />}
		</div>
	</form>;
export default SearchInput;