//@ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ChangeEmailComponent from './ChangeEmailComponent';
import { serializeForm } from '../../../../utils/form';
import { useAppSelector } from '../../../../state/hooks';
import ROUTES from '../../../../constants/routes';
import { goToRoute } from '../../../../utils/navigation';
import type { SerializedChangeEmailFormDataType } from './ChangeEmailTypes';
import type { FC } from 'react';
import { isObjectEmpty } from '@/utils/object';
import { changeEmailActions, ChangeEmailStatus } from '@/reducers/changeEmailSlice';
import { verifyPasswordAction, verifyPasswordActions, VerifyPasswordStatus } from '@/reducers/verifyPasswordSlice';
import { changeEmailAction } from '@/actions/profileActions';
import { profileActions } from '@/reducers/profileSlice';
import { buildNotification } from '@/utils/notification';
import { profileMessages } from '@/messages/profile';
import { NotificationType } from '@/enums/Notification';
import { NotificationObject } from '@/types/Notification';
const ChangeEmail: FC<{
  path: string;
}> = () => {
  const {
    register: registerInput,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const customerInfo = useAppSelector(state => state.auth.token?.customerInfo);
  const customerAccessToken = useAppSelector(state => state.auth.token?.customerAccessToken);
  const notification: NotificationObject | undefined = useAppSelector(({
    changeEmail,
    verifyPassword
  }) => changeEmail.notification ?? verifyPassword.notification);
  const changeEmailStatus: ChangeEmailStatus | undefined = useAppSelector(({
    changeEmail
  }) => changeEmail.status);
  const verifyPasswordStatus: VerifyPasswordStatus | undefined = useAppSelector(state => state.verifyPassword.status);
  const dispatch = useDispatch();
  const [serializedFormData, setSerializedFormData] = useState<SerializedChangeEmailFormDataType>({});
  const formRef = useRef<HTMLFormElement>(null);

  // To clean any previous status/notification when leaving the page
  useEffect(() => () => {
    dispatch(changeEmailActions.resetState());
    dispatch(verifyPasswordActions.resetState());
  }, [dispatch]);
  useEffect(() => {
    if (verifyPasswordStatus === 'VERIFY_PASSWORD_SUCCESSFUL' && customerAccessToken) {
      dispatch(changeEmailAction({
        customerAccessToken,
        customer: {
          email: serializedFormData.email
        }
      }));
      dispatch(verifyPasswordActions.resetState());
    }
  }, [dispatch, verifyPasswordStatus, customerAccessToken, serializedFormData]);
  useEffect(() => {
    if (changeEmailStatus === 'CHANGE_EMAIL_SUCCESSFUL') {
      goToRoute(`${ROUTES.myPlace}${ROUTES.profileSettings}`);
      // The email was changed, let's not forget to show the success notification in the change profile page!
      dispatch(profileActions.updateNotification(buildNotification(profileMessages.success_updating_profile, NotificationType.success)));
    }
  }, [dispatch, changeEmailStatus, customerAccessToken, serializedFormData]);
  const handleChangeForm = useCallback(() => {
    if (formRef.current) {
      const formElement: HTMLFormElement = formRef.current;
      const serializedData = serializeForm(formElement);
      setSerializedFormData({
        ...serializedData
      });
    }
  }, [setSerializedFormData]);
  const onSubmitForm = useCallback(() => {
    dispatch(changeEmailActions.resetState());
    dispatch(verifyPasswordActions.resetState());
    const {
      email,
      confirmEmail,
      password
    } = serializedFormData;
    if (confirmEmail !== email) {
      dispatch(changeEmailActions.updateNotification(buildNotification(profileMessages.error_email_and_confirm_email_are_not_the_same, NotificationType.error)));
      return;
    }
    if (isObjectEmpty(errors) && !isObjectEmpty(serializedFormData) && password && customerInfo) {
      dispatch(verifyPasswordAction({
        email: customerInfo.email,
        password
      }));
    }
  }, [dispatch, errors, serializedFormData, customerInfo]);
  return <ChangeEmailComponent notification={notification} onChangeForm={handleChangeForm} formRef={formRef} validationErrors={errors} isLoading={changeEmailStatus === 'CHANGE_EMAIL_PENDING' || verifyPasswordStatus === 'VERIFY_PASSWORD_PENDING'} onSubmitForm={handleSubmit(onSubmitForm)} registerInput={registerInput} data-sentry-element="ChangeEmailComponent" data-sentry-component="ChangeEmail" data-sentry-source-file="ChangeEmail.tsx" />;
};
export default ChangeEmail;