"use client";

import { template } from "lodash";
import React, { FC, useEffect, useState } from "react";
import * as EmailValidator from "email-validator";
import { FormProvider, FieldValues, UseFormRegister, UseFormReturn } from "react-hook-form";
import Button from "../Button";
import Dropdown from "../dropdown/Dropdown";
import Input from "../input";
import { MESSAGE_MAX_CHARACTER_LENGTH } from "./constants";
import { ContactFields } from "./types";
import TextField from "../TextField";
import OnAirNotification from "../OnAirNotification";
import { useAppSelector } from "../../state/hooks";
import { FileUploader } from "../file-uploader";
import { LoginRequiredCategoryError } from "./LoginRequiredCategoryError";
import { NotificationObject } from "@/types/Notification";
import { categoryOptions, deletePersonalDataCategory, requestARefundCategory } from "@/enums/Freshdesk";
import { commonTexts } from "@/messages/common";
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from "@/errors";
import { ButtonType } from "@/enums/ButtonType";
type Props = {
  readonly formRef: React.RefObject<HTMLFormElement>;
  readonly notificationRef: React.RefObject<HTMLDivElement>;
  readonly validationErrors: {
    [x: string]: any;
  };
  readonly onSubmitForm: () => void;
  readonly registerInput: UseFormRegister<FieldValues>;
  readonly formMethods: UseFormReturn<FieldValues, object>;
  readonly notification?: NotificationObject;
  readonly initialFields?: Partial<ContactFields>;
  readonly fileName?: string;
  readonly setFileName: (fileName: string) => void;
  readonly onRemoveFile: () => void;
  readonly isLoading: boolean;
  readonly category: number;
  readonly setCategory: React.Dispatch<React.SetStateAction<number>>;
};
export const ContactForm: FC<Props> = ({
  formRef,
  notificationRef,
  validationErrors,
  onSubmitForm,
  registerInput,
  formMethods,
  notification,
  initialFields,
  fileName,
  setFileName,
  onRemoveFile,
  isLoading,
  category,
  setCategory
}) => {
  const [categoryRequiresAuthentication, setCategoryRequiresAuthentication] = useState<boolean>(false);
  const categoryValues = Object.values(categoryOptions).map(i => i.title);
  const customerInfo = useAppSelector(state => state.auth.token?.customerInfo);
  const isLoggedIn = useAppSelector(state => state.auth.loginStatus === "LOGGED_IN");
  const handleCategoryChange = (selectedCategory: number) => {
    if (selectedCategory >= 0 && selectedCategory <= categoryValues.length) {
      setCategory(selectedCategory);
    }
  };
  useEffect(() => {
    setCategoryRequiresAuthentication(category === deletePersonalDataCategory.type || category === requestARefundCategory.type);
  }, [category]);
  return <>
			<div ref={notificationRef}>
				{notification && <OnAirNotification className="mt-2" text={notification.text} type={notification.type} testId="notification" />}
			</div>
			<FormProvider {...formMethods} data-sentry-element="FormProvider" data-sentry-source-file="ContactForm.tsx">
				<form autoComplete="off" ref={formRef} onSubmit={onSubmitForm} className="m-0">
					<Dropdown options={categoryValues} validationRules={{
          validate: {
            doesOptionRequireLogin: (option: number) => categoryOptions[option].requiresAuth && !isLoggedIn ? "err" : true // don't show any error message
          }
        }} selected={category} selectCallback={handleCategoryChange} label={commonTexts.category} inputName="type" // set as 'type' because freshDesk expects with that name
        error={categoryOptions[category].requiresAuth && !isLoggedIn ? <LoginRequiredCategoryError categoryType={category} /> : ""} data-sentry-element="Dropdown" data-sentry-source-file="ContactForm.tsx" />
					<div className="name-and-surname">
						<Input className="my-2" label={commonTexts.first_name} icon="oa-account" defaultValue={initialFields?.firstName ?? customerInfo?.firstName} autoGeneratedProps={registerInput("firstName", {
            required: {
              value: true,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
                field: commonTexts.first_name
              })
            },
            pattern: {
              // Only letters and spaces
              value: /^[a-zA-Z\s]*$/,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid])({
                field: commonTexts.first_name
              })
            }
          })} error={validationErrors?.firstName?.message} testId="firstNameInput" readonly={categoryRequiresAuthentication} data-sentry-element="Input" data-sentry-source-file="ContactForm.tsx" />
						<Input className="my-2" label={commonTexts.last_name} defaultValue={initialFields?.lastName ?? customerInfo?.lastName} autoGeneratedProps={registerInput("lastName", {
            required: {
              value: true,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
                field: commonTexts.last_name
              })
            },
            pattern: {
              // Only letters and spaces
              value: /^[a-zA-Z\s]*$/,
              message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid])({
                field: commonTexts.last_name
              })
            }
          })} error={validationErrors?.lastName?.message} testId="lastNameInput" readonly={categoryRequiresAuthentication} data-sentry-element="Input" data-sentry-source-file="ContactForm.tsx" />
					</div>
					<Input className="mb-2" label={commonTexts.email_address} icon="oa-email" defaultValue={initialFields?.email ?? customerInfo?.email} autoGeneratedProps={registerInput("email", {
          required: {
            value: true,
            message: template(VALIDATION_ERROR_MESSAGES[`${ValidationErrorCode.required}-an-word`])({
              field: commonTexts.email_address
            })
          },
          validate: {
            validEmail: (value: string) => EmailValidator.validate(value) || VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail]
          }
        })} error={validationErrors?.email?.message} testId="emailInput" readonly={categoryRequiresAuthentication} data-sentry-element="Input" data-sentry-source-file="ContactForm.tsx" />
					<Input className="mb-2" label={commonTexts.subject} defaultValue={initialFields?.subject} autoGeneratedProps={registerInput("subject", {
          required: {
            value: true,
            message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
              field: commonTexts.subject
            })
          }
        })} error={validationErrors?.subject?.message} testId="subjectInput" data-sentry-element="Input" data-sentry-source-file="ContactForm.tsx" />
					<TextField className="mb-2" label={commonTexts.message} defaultValue={initialFields?.description} autoGeneratedProps={registerInput("description", {
          required: {
            value: true,
            message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
              field: commonTexts.message
            })
          }
        })} rowSize={8} maxLength={MESSAGE_MAX_CHARACTER_LENGTH} error={validationErrors?.description?.message} testId="descriptionInput" data-sentry-element="TextField" data-sentry-source-file="ContactForm.tsx" />
					<FileUploader registerFileInput={registerInput("attachment", {
          onChange: event => setFileName(event.target.files?.[0]?.name),
          validate: {
            maxSize: (files?: FileList) => {
              const file = files?.item(0);
              if (file) {
                const {
                  size,
                  name
                } = file;
                // 4 MB in bytes
                return size <= 4e6 || template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.fileIsTooBig])({
                  name,
                  size: "4MB"
                });
              }
              return true; // don't show any error message
            }
          }
        })} fileName={fileName} onRemoveFile={onRemoveFile} errorMessage={validationErrors?.attachment?.message} data-sentry-element="FileUploader" data-sentry-source-file="ContactForm.tsx" />
					<Button className="mt-3" type={ButtonType.SOLID_PRIMARY} grow onClick={onSubmitForm} testId="submitButton" loading={isLoading} data-sentry-element="Button" data-sentry-source-file="ContactForm.tsx">
						Submit
					</Button>
				</form>
			</FormProvider>
		</>;
};