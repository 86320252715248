import React, { FC } from "react";
import SellingPointsSectionComponent from "./SellingPointsSectionComponent";
import { ContentfulSellingPoints } from "@/types/contentful";
const SellingPointBlock: FC<{
  sellingPointsBlock: ContentfulSellingPoints;
  isFirstBlock?: boolean;
}> = ({
  sellingPointsBlock,
  isFirstBlock
}) => <SellingPointsSectionComponent sellingPoints={sellingPointsBlock.sellingPoints} title={sellingPointsBlock.title} showTitle={sellingPointsBlock.showTitle} isFirstBlock={isFirstBlock} data-sentry-element="SellingPointsSectionComponent" data-sentry-component="SellingPointBlock" data-sentry-source-file="index.tsx" />;
export default SellingPointBlock;