'use client';
import { ContentProtection } from '@/utils/useContentProtection';
import { useEffect, useState } from 'react';

interface UseAuthXMLBugCheckProps {
    isLive?: boolean;
    contentProtection: ContentProtection;
    hasTicketInShopify: boolean;
}

export const useAuthXMLBugCheck = ({
    isLive,
    contentProtection,
    hasTicketInShopify,
}: UseAuthXMLBugCheckProps) => {
    const [authenticationError, setAuthenticationError] = useState(false);

    // This is the condition that occurs when a user has more tickets than the AuthXML can handle (25)
    // The AuthXML will say that the user can't watch the event, but the user has the ticket in Shopify
    // This only happens on internal testing accounts.
    // Show a forbidden error when this happens
    useEffect(() => {
        if (
            isLive &&
            !contentProtection.isLoading &&
            !contentProtection.hasAccess &&
            hasTicketInShopify
        ) {
            return setAuthenticationError(true);
        }
        return setAuthenticationError(false);
    }, [
        isLive,
        contentProtection.isLoading,
        contentProtection.hasAccess,
        hasTicketInShopify,
        setAuthenticationError,
    ]);

    return authenticationError;
};
