import { FC, ReactNode } from 'react';
const HeroSubtitle: FC<{
  children: ReactNode;
}> = ({
  children
}) => <h2 className="subtitle uppercase-first-letter" style={{
  color: 'var(--shade-3)'
}} data-sentry-component="HeroSubtitle" data-sentry-source-file="HeroSubtitle.tsx">
        {children}
    </h2>;
export default HeroSubtitle;