'use client';

import React, { useEffect } from 'react';
import ForgotPasswordForm from '../../forgot-password';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';
const ForgotPassword = ({
  pageContext,
  location
}: {
  pageContext: any;
  location?: Location;
}) => {
  useEffect(() => {
    logPageViewToSegment(`/${pageContext.slug}`);
  }, []);
  return <Shell isFooterTopLineDisabled ogp={pageContext?.seo} pathname={location?.pathname} data-sentry-element="Shell" data-sentry-component="ForgotPassword" data-sentry-source-file="index.tsx">
            <div className="container" style={{
      marginTop: 200,
      marginBottom: 200
    }}>
                <ForgotPasswordForm data-sentry-element="ForgotPasswordForm" data-sentry-source-file="index.tsx" />
            </div>
        </Shell>;
};
export default ForgotPassword;