'use client';

import React, { FC } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import { useCountryCodeSelector } from '../../state/hooks';
import { ContentfulEventOption, EventVariantOption } from '@/types/contentful';
import { formatDisplayPrice } from '@/utils/i18n';
interface Props {
  option: EventVariantOption;
  selectedOptions: ContentfulEventOption[];
}
export const HeroEventConfiguratorOption: FC<Props> = ({
  option,
  selectedOptions
}) => {
  const customerCountryCode = useCountryCodeSelector();
  return <div className="d-flex w-100 justify-content-center justify-content-md-between" data-sentry-component="HeroEventConfiguratorOption" data-sentry-source-file="HeroEventConfiguratorOption.tsx">
            <div className="event-configurator-option__icons d-flex justify-content-center justify-content-md-start align-items-center">
                {option?.icons && option?.icons?.length > 0 && option.icons.map(icon => <Icon key={`configuration-option-icon-${icon.type}-${option.value}`} icon={icon.type} className={classNames('event-configurator-option__icon', {
        'event-configurator-option__icon--active': selectedOptions.some(({
          value
        }) => value === option.value)
      })} />)}
            </div>

            <div className="d-none d-md-flex justify-content-end align-items-center" key={`option-${option.value}-price`}>
                <span>
                    {option?.price && formatDisplayPrice(customerCountryCode, option?.price)}
                </span>
            </div>
        </div>;
};