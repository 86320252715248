import React from "react";
import { ContactAddress } from "./ContactAddress";
import { ContactForm } from "./ContactForm";
import { ContactMapLocation } from "./ContactMapLocation";
import { CONTACT_PAGE_MESSAGES } from "./messages";
import { onAirSocialsLinks } from "../../utils/social";
import HeroComponent from "../hero/HeroComponent";
import HeroTitle from "../hero/HeroTitle";
import Socials from "../Socials";
import type { FC } from "react";
const SocialsAndLocation: FC = () => <>
		<div className="contact-socials">
			<Socials links={onAirSocialsLinks} data-sentry-element="Socials" data-sentry-source-file="ContactComponent.tsx" />
		</div>
		<ContactAddress data-sentry-element="ContactAddress" data-sentry-source-file="ContactComponent.tsx" />
		<ContactMapLocation data-sentry-element="ContactMapLocation" data-sentry-source-file="ContactComponent.tsx" />
	</>;
type Props = React.ComponentProps<typeof ContactForm>;
const ContactComponent: FC<Props> = props => {
  return <div className="contact-page__wrapper mb-5" data-sentry-component="ContactComponent" data-sentry-source-file="ContactComponent.tsx">
			<HeroComponent backgroundImageProps={{
      imageUrl: "/assets/images/on-air-building.jpg",
      alt: "on air building"
    }} data-sentry-element="HeroComponent" data-sentry-source-file="ContactComponent.tsx">
				<div className="hero__content">
					<HeroTitle data-sentry-element="HeroTitle" data-sentry-source-file="ContactComponent.tsx">{CONTACT_PAGE_MESSAGES.title}</HeroTitle>
					<div className="mt-5" />
				</div>
			</HeroComponent>
			<div className="container contact-page__content">
				<div className="item pe-5 pt-4">
					<div className="contact-description body-text mb-2">
						{CONTACT_PAGE_MESSAGES.description}
					</div>
					<div className="d-none d-sm-block">
						<SocialsAndLocation data-sentry-element="SocialsAndLocation" data-sentry-source-file="ContactComponent.tsx" />
					</div>
				</div>
				<div className="item">
					<ContactForm {...props} data-sentry-element="ContactForm" data-sentry-source-file="ContactComponent.tsx" />
				</div>
				<div className="d-block d-sm-none p-2">
					<SocialsAndLocation data-sentry-element="SocialsAndLocation" data-sentry-source-file="ContactComponent.tsx" />
				</div>
			</div>
		</div>;
};
export default ContactComponent;