'use client';

/* eslint-disable no-underscore-dangle */
import React, { FC, useCallback, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { template } from 'lodash';
import type { SerializedPreviewLoginFormDataType, LoginInfoType } from './preview-login-block-types';
import { serializeForm } from '../../../utils/form';
import Input from '../../input';
import Button from '../../Button';
import { previewPagesTexts } from './messages';
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from '@/errors';
import { commonTexts } from '@/messages/common';
import { ButtonType } from '@/enums/ButtonType';
type Props = {
  accessCode: string;
  setHasAccess: (hasAccess: boolean) => void;
};
const PreviewPageSubmitBlock: FC<Props> = ({
  accessCode,
  setHasAccess
}) => {
  const [loginInfo, setLoginInfo] = useState<LoginInfoType>({});
  const [serializedFormData, setSerializedFormData] = useState<SerializedPreviewLoginFormDataType>({});
  const formRef = useRef<HTMLFormElement>(null);
  const onSubmitForm = useCallback(() => {
    // Fake loading for a few ms
    setLoginInfo({
      ...loginInfo,
      isLoading: true
    });
    setTimeout(() => {
      const accessGranted = accessCode === serializedFormData.password;
      setLoginInfo({
        hasError: !accessGranted,
        isLoading: false
      });
      setHasAccess(accessGranted);
    }, 500);
  }, [serializedFormData, loginInfo, accessCode, setHasAccess]);
  const onChangeForm = useCallback(() => {
    if (formRef?.current) {
      const formElement: HTMLFormElement = formRef.current;
      const serializedData = serializeForm(formElement);
      setSerializedFormData(serializedData);
    }
  }, [setSerializedFormData]);
  const {
    register: registerInput,
    handleSubmit
  } = useForm();
  return <div className="container text-center preview-login my-5" data-sentry-component="PreviewPageSubmitBlock" data-sentry-source-file="PreviewPageSubmitBlock.tsx">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div className="px-sm-1 px-md-3">
                        <h2 className="subtitle mb-3">
                            {previewPagesTexts.access_video}
                        </h2>
                        <p className="body-text mb-3">
                            {previewPagesTexts.access_video_info}
                        </p>
                    </div>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmitForm)} onChange={onChangeForm} ref={formRef}>
                        <Input onEnter={handleSubmit(onSubmitForm)} type="password" icon="oa-lock" autoGeneratedProps={registerInput('password', {
            required: {
              value: true,
              message: template(VALIDATION_ERROR_MESSAGES[`${ValidationErrorCode.required}-personal-approach`])({
                field: commonTexts.password
              })
            }
          })} testId="previewLoginPasswordInput" data-sentry-element="Input" data-sentry-source-file="PreviewPageSubmitBlock.tsx" />
                        {loginInfo.hasError && <p className="onair-input-error caption-text text-left" data-testid="errorFieldTest">
                                {previewPagesTexts.password_error}
                            </p>}

                        <Button className="mt-4" type={ButtonType.SOLID_PRIMARY} grow onClick={handleSubmit(onSubmitForm)} loading={loginInfo.isLoading} testId="previewSubmitButton" data-sentry-element="Button" data-sentry-source-file="PreviewPageSubmitBlock.tsx">
                            {previewPagesTexts.button_text}
                        </Button>
                    </form>
                </div>
            </div>
        </div>;
};
export default PreviewPageSubmitBlock;