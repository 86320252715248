import React from 'react';
import type { FC, ReactNode } from 'react';
const MyPlaceTitle: FC<{
  children: ReactNode;
  testId?: string;
}> = ({
  children,
  testId
}) => <h2 className="subtitle my-place-title" test-id={testId} data-sentry-component="MyPlaceTitle" data-sentry-source-file="MyPlaceTitle.tsx">
        {children}
    </h2>;
export default MyPlaceTitle;