'use client';

import React, { useEffect } from 'react';
import Cart from '../../cart';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';
const CartPage = ({
  location,
  pageContext
}: {
  location?: Location;
  pageContext: any;
}) => {
  useEffect(() => {
    logPageViewToSegment(`/${pageContext.slug}`);
  }, []);
  return <Shell isFooterTopLineDisabled pathname={location?.pathname} ogp={pageContext?.seo} data-sentry-element="Shell" data-sentry-component="CartPage" data-sentry-source-file="index.tsx">
            <Cart data-sentry-element="Cart" data-sentry-source-file="index.tsx" />
        </Shell>;
};
export default CartPage;