import React, { FC, useRef, useState } from "react";
import { NormalizedCartListItem } from "../../../types/cart";
import { userAgentDetails } from "../../../utils/user-agent";
import { CartAddOnsProductList } from "./CartAddOnsProductList";
import { CartTotal } from "./CartTotal";
import { StickyCartTotal } from "./StickyCartTotal";
type CartBottomProps = {
  normalizedCartList: Array<NormalizedCartListItem>;
};
const CartBottom: FC<CartBottomProps> = ({
  normalizedCartList
}) => {
  const [loading, setLoading] = useState(false);
  const checkoutRef = useRef<HTMLDivElement>(null);
  const {
    isMobile
  } = userAgentDetails();
  return <div className="row cart-bottom__wrapper mt-5 mt-sm-3" data-sentry-component="CartBottom" data-sentry-source-file="index.tsx">
			<div className="cart__add-ons-wrapper col-lg-9 col-md-8 col-sm-7 col-xs-6">
				<CartAddOnsProductList data-sentry-element="CartAddOnsProductList" data-sentry-source-file="index.tsx" />
			</div>
			<div className="cart-total-rows-wrapper col-sm-5 col-md-4 offset-md-0 col-lg-3 offset-lg-0 mt-5 mt-sm-0 pb-2 pb-sm-0 mt-md-0">
				<CartTotal normalizedCartList={normalizedCartList} handleIsLoading={setLoading} checkoutRef={checkoutRef} isLoading={loading} data-sentry-element="CartTotal" data-sentry-source-file="index.tsx" />
			</div>

			{isMobile && <StickyCartTotal normalizedCartList={normalizedCartList} handleIsLoading={setLoading} checkoutRef={checkoutRef} isLoading={loading} />}
		</div>;
};
export default CartBottom;