import React from 'react';
import Button from '../../Button';
import ROUTES from '../../../constants/routes';
import LinkInternal from '../../LinkInternal';
import { cartPageTexts } from '../messages';
import Icon from '../../Icon';
import OnAirNotification from '../../OnAirNotification';
import type { FC } from 'react';
import { NotificationType } from '@/enums/Notification';
const CartEmptyComponent: FC<{
  showNotification?: boolean;
}> = ({
  showNotification
}) => <div className="cart-empty__wrapper text-center" data-sentry-component="CartEmptyComponent" data-sentry-source-file="CartEmpty.tsx">
        {showNotification && <OnAirNotification type={NotificationType.info} text={cartPageTexts.abandoned_cart_opened_different_country_msg} />}
        <div className="cart-empty__content">
            <Icon icon="oa-cart" data-sentry-element="Icon" data-sentry-source-file="CartEmpty.tsx" />
            <span className="subtitle mt-3">
                {cartPageTexts.empty_cart_title}
            </span>
            <span className="body-text mt-2">
                {cartPageTexts.empty_cart_desc}
            </span>
            <LinkInternal to={ROUTES.home} className="d-flex justify-content-center" data-sentry-element="LinkInternal" data-sentry-source-file="CartEmpty.tsx">
                <Button className="mt-2 me-0 btn-primary btn-browse-streams" data-sentry-element="Button" data-sentry-source-file="CartEmpty.tsx">
                    {cartPageTexts.empty_cart_btn_text}
                </Button>
            </LinkInternal>
        </div>
    </div>;
export default CartEmptyComponent;