"use client";

import React, { FC, useEffect } from "react";
import { fetchCartEventsAndMerchandisesContentfulDataAction, resetNotificationStatus } from "../../state/actions/cartActions";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { CartState } from "../../state/reducers/cartSlice";
import { getProductIdsFromCartLines } from "./utils";
import CartPageComponent from "./cart-wrapper/CartPageComponent";
const Cart: FC = () => {
  const dispatch = useAppDispatch();
  const {
    cart,
    normalizedCartList,
    showNotification
  }: CartState = useAppSelector(state => state.cartSlice);
  useEffect(() => () => {
    dispatch(resetNotificationStatus());
  }, [dispatch]);
  useEffect(() => {
    const productAndAddonIdsFromCartLines = getProductIdsFromCartLines(cart?.lines);
    if (productAndAddonIdsFromCartLines.events.length || productAndAddonIdsFromCartLines.addons.length) {
      dispatch(fetchCartEventsAndMerchandisesContentfulDataAction({
        productIds: productAndAddonIdsFromCartLines
      }));
    }
  }, [cart?.lines, dispatch]);
  return <CartPageComponent normalizedCartList={normalizedCartList} showNotification={showNotification} data-sentry-element="CartPageComponent" data-sentry-component="Cart" data-sentry-source-file="index.tsx" />;
};
export default Cart;