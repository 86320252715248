'use client';

import React, { useEffect, useState } from 'react';
import PreviewPageSubmitBlock from './PreviewPageSubmitBlock';
import PreviewPageContent from './PreviewPageContent';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';
import { ContentfulFlexiblePageBlock, ContentfulStream, ContentfulStreamSecurity } from '@/types/contentful';
const PreviewPage = ({
  location,
  blocks,
  slug,
  streams,
  accessCode,
  streamSecurity
}: {
  location?: Location;
  blocks?: ContentfulFlexiblePageBlock[];
  streams: ContentfulStream[];
  accessCode: string;
  slug: string;
  streamSecurity: ContentfulStreamSecurity;
}) => {
  const [hasAccess, setHasAccess] = useState(false);
  useEffect(() => {
    logPageViewToSegment(`/preview/${slug}`);
  }, []);
  return <Shell isFooterTopLineDisabled isNewsletterSignupVisible pathname={location?.pathname} data-sentry-element="Shell" data-sentry-component="PreviewPage" data-sentry-source-file="index.tsx">
            {hasAccess ? <PreviewPageContent blocks={blocks} streams={streams} streamSecurity={streamSecurity} accessCode={accessCode} slug={slug} /> : <PreviewPageSubmitBlock accessCode={accessCode} setHasAccess={hasAccessValue => setHasAccess(hasAccessValue)} />}
        </Shell>;
};
export default PreviewPage;