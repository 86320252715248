/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC } from 'react';
import EventCarouselComponent from './EventCarouselComponent';
import { ContentfulEventCarousel } from '@/types/contentful';
const EventCarousel: FC<{
  carousel: ContentfulEventCarousel;
  small?: boolean;
  isFirstBlock?: boolean;
}> = ({
  carousel,
  small = false,
  isFirstBlock
}) => {
  if (!carousel.events.length) return null;
  return <EventCarouselComponent carousel={carousel} small={small} isFirstBlock={isFirstBlock} data-sentry-element="EventCarouselComponent" data-sentry-component="EventCarousel" data-sentry-source-file="index.tsx" />;
};
export default EventCarousel;