import { format } from 'date-fns';
import Image from 'next/image';
import { FC } from 'react';
import { ContentfulImage } from '@/types/contentful';
interface HeroMetaProps {
  icon?: ContentfulImage;
  date?: string;
  duration?: string;
}
const HeroMetaContent: FC<HeroMetaProps> = ({
  icon,
  date,
  duration
}) => {
  return <div className="hero__meta-content caption-text" data-sentry-component="HeroMetaContent" data-sentry-source-file="HeroMetaContent.tsx">
            {icon?.asset?.url && <>
                    <Image src={icon.asset.url} alt={icon.alt} width={32} height={32} />
                    {icon?.title && <p>{icon.title}</p>}
                    {(date || duration) && <span>•</span>}
                </>}

            {date && <>
                    {format(new Date(date), 'MMMM dd, yyyy')}
                    {duration && <span>•</span>}
                </>}

            {duration && <p>{duration}</p>}
        </div>;
};
export default HeroMetaContent;