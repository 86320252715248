'use client';

import React, { useEffect } from 'react';
import { ChangeCookieSettings } from './ChangeCookieSettings';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';
const CookiePolicyPage = ({
  location,
  pageContext
}: {
  location?: Location;
  pageContext: any;
}) => {
  useEffect(() => {
    logPageViewToSegment(`/${pageContext.slug}`);
  }, []);
  return <Shell isFooterTopLineDisabled pathname={location?.pathname} ogp={pageContext?.seo} data-sentry-element="Shell" data-sentry-component="CookiePolicyPage" data-sentry-source-file="index.tsx">
            <ChangeCookieSettings data-sentry-element="ChangeCookieSettings" data-sentry-source-file="index.tsx" />
        </Shell>;
};
export default CookiePolicyPage;