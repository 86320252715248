import React, { FC } from 'react';
import LinkExternal from '../LinkExternal';
type Props = {
  query: string;
};
const SearchError: FC<Props> = ({
  query
}) => <div className="error container" data-sentry-component="SearchError" data-sentry-source-file="SearchError.tsx">
        <div className="row mb-2">
            <span className="title">Your search for ”</span>
            <span className="title colored">{query}</span>
            <span className="title">” didn&apos;t return any results</span>
        </div>
        <div className="row">
            <h5 className="body-title">Search tips</h5>
        </div>
        <ul className="list">
            <li className="body-text">
                Try searching by event title or artist name.
            </li>
            <li className="body-text">Double check your spelling for typos.</li>
            <li className="body-text">
                Broaden your search by using fewer keywords.
            </li>
        </ul>
        <div className="row">
            <span className="body-title me-1">Need additional help?</span>
            <LinkExternal className="body-title" to="http://helpdesk.onair.events/" data-sentry-element="LinkExternal" data-sentry-source-file="SearchError.tsx">
                Contact helpdesk
            </LinkExternal>
        </div>

        <div className="row mt-4 mb-2">
            <span className="body-title">Help us make our search better</span>
        </div>

        <div className="row">
            <LinkExternal className="body-text" to="https://cx.retently.com/al/65a548f68b17960c7dbb0104/" data-sentry-element="LinkExternal" data-sentry-source-file="SearchError.tsx">
                Send feedback
            </LinkExternal>
        </div>
    </div>;
export default SearchError;