'use client';

import React, { useEffect } from 'react';
import LoginRegister from '../../login-register/LoginRegister';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';
const LoginPage = ({
  pageContext
}: {
  pageContext: any;
}) => {
  useEffect(() => {
    logPageViewToSegment(`/${pageContext.slug}`);
  }, []);
  return <Shell isFooterTopLineDisabled ogp={pageContext.seo} data-sentry-element="Shell" data-sentry-component="LoginPage" data-sentry-source-file="index.tsx">
            <LoginRegister data-sentry-element="LoginRegister" data-sentry-source-file="index.tsx" />
        </Shell>;
};
export default LoginPage;