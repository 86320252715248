import React from 'react';
import ROUTES from '../../../constants/routes';
import { goToRoute } from '../../../utils/navigation';
import Button from '../../Button';
import Icon from '../../Icon';
import messages from '../messages';
import { ButtonType } from '@/enums/ButtonType';
const EmptyState = () => <div className="my-tickets-empty-state d-flex flex-column align-items-center justify-content-center mb-4" data-sentry-component="EmptyState" data-sentry-source-file="EmptyState.tsx">
        <Icon icon="oa-ticket" className="my-tickets-empty-state__ticket-icon color-background-secondary" data-sentry-element="Icon" data-sentry-source-file="EmptyState.tsx" />
        <p className="body-text my-3">{messages.my_tickets.empty_state_body}</p>
        <Button type={ButtonType.OUTLINE_SECONDARY} className="m-0" onClick={() => goToRoute(ROUTES.home)} data-sentry-element="Button" data-sentry-source-file="EmptyState.tsx">
            {messages.my_tickets.browse_shows}
        </Button>
    </div>;
export default EmptyState;