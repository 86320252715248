import React, { FC } from 'react';
import classNames from 'classnames';
import messages from './messages';
type HeroEventConfiguratorDateType = {
  isSingleVariant: boolean;
  releaseDate?: string;
};
const HeroEventConfiguratorDate: FC<HeroEventConfiguratorDateType> = ({
  releaseDate,
  isSingleVariant
}) => {
  // If the releaseDate is not provided, don't render the component
  if (!releaseDate) return null;
  return <div className="d-flex flex-column justify-content-center justify-content-md-start me-4" data-testid="release-date-container" data-sentry-component="HeroEventConfiguratorDate" data-sentry-source-file="HeroEventConfiguratorDate.tsx">
            <h4 className="caption-text uppercase">{messages.releaseDate}</h4>
            <p className={classNames('body-text pt-1', {
      'mb-1': !isSingleVariant
    })}>
                {releaseDate}
            </p>
        </div>;
};
export default HeroEventConfiguratorDate;