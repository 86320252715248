import React, { FC } from 'react';
import Icon from '../Icon';
type Props = {
  onRemoveFile: () => void;
  text?: string;
};
export const UploadedFileComponent: FC<Props> = ({
  onRemoveFile,
  text
}) => text ? <div className="uploaded-file">
            <div className="body-text text-start uppercase-first-letter" data-testid="fileNameWrap">
                {text}
            </div>
            <button className="unstyled-button" type="button" data-testid="removeAttachmentButton" aria-label="Remove attachment" onClick={onRemoveFile}>
                <Icon icon="oa-close" />
            </button>
        </div> : null;