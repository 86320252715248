'use client';

import React from 'react';
import ContentBlockComponent from './ContentBlockComponent';
import { renderContentfulDescription } from '../../utils/contentful';
import { generateShareInSocialsLinks } from '../../utils/social';
import { useContentfulButtonProps } from '../../hooks/useContentfulButtonProps';
import type { FC } from 'react';
import { ContentfulContentBlock, ContentfulImage } from '@/types/contentful';
const ContentBlock: FC<{
  contentBlock: ContentfulContentBlock;
  showTransparentBackground?: boolean;
  isFirstBlock?: boolean;
}> = ({
  contentBlock,
  showTransparentBackground,
  isFirstBlock
}) => {
  const contentfulButtonProps = useContentfulButtonProps(contentBlock.button);
  return <ContentBlockComponent isFirstBlock={isFirstBlock} event={contentBlock.event} key={contentBlock.title} title={contentBlock.title} subtitle={contentBlock.subtitle} showAppStoreBanners={contentBlock.showAppStoreBanners} showTransparentBackground={showTransparentBackground}
  // description={<p>Hello</p>}
  description={renderContentfulDescription(contentBlock.description)} image={contentBlock.image as ContentfulImage} buttonProps={contentfulButtonProps} imagePosition={contentBlock.imagePosition} links={[...(contentBlock.shouldShowSocialButtons ? generateShareInSocialsLinks() : []), ...(contentBlock.links || [])]} data-sentry-element="ContentBlockComponent" data-sentry-component="ContentBlock" data-sentry-source-file="index.tsx" />;
};
export default ContentBlock;