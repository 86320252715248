'use client';

import React, { useEffect } from 'react';
import { Event } from '@/types/contentful';
import { TheoPlayerContextWrapper } from '../../theo-player/theo-player-context';
import { EventPage } from './EventPage';
import NotFoundPage from '../not-found-page';
import { EventsByTag } from '@/types/page-context';
type EventPageProps = {
  readonly location: Location;
  readonly event?: Event;
  readonly eventsByTag: EventsByTag;
};
const EventPageWithTheoContext = ({
  location,
  event,
  eventsByTag
}: EventPageProps) => {
  useEffect(() => {
    setTimeout(() => {
      window?.analytics?.page();
    }, 1000);
  }, []);
  if (!event) return <NotFoundPage />;
  return <TheoPlayerContextWrapper data-sentry-element="TheoPlayerContextWrapper" data-sentry-component="EventPageWithTheoContext" data-sentry-source-file="index.tsx">
            <EventPage location={location} event={event} eventsByTag={eventsByTag} data-sentry-element="EventPage" data-sentry-source-file="index.tsx" />
        </TheoPlayerContextWrapper>;
};
export default EventPageWithTheoContext;