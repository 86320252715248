/* eslint-disable import/no-unresolved */
"use client";

import React, { useCallback, useEffect, useState } from "react";
import ChangePasswordFormComponent from "./component";
import ROUTES from "../../constants/routes";
import { getItemFromLocalStorage } from "../../state/localStorage";
import FORGOT_PASSWORD_STORED_EMAIL from "../forgot-password/constants";
import useRedirectIfLoggedIn from "../../hooks/useRedirectIfLoggedIn";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import type { FC } from "react";
import { navigate } from "@/utils/navigation";
import { VALIDATION_ERROR_MESSAGES, ValidationError, ValidationErrorCode } from "@/errors";
import { forgotPasswordActions, ForgotPasswordStatus } from "@/reducers/forgotPasswordSlice";
import { loginWithEmailAndPassword } from "@/reducers/authSlice";
import { changePasswordWithResetTokenAction } from "@/actions/forgotPasswordActions";
interface ChangePasswordFormProps {
  userId: string;
  resetToken: string;
}
const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  userId,
  resetToken
}) => {
  useRedirectIfLoggedIn(ROUTES.myPlace);
  const [password, setPassword] = useState<string>("");
  const auth = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const notification = useAppSelector(({
    forgotPassword
  }) => forgotPassword.notification);
  const forgotPasswordStatus = useAppSelector(({
    forgotPassword
  }) => forgotPassword.status);
  const [localError, setLocalError] = useState<ValidationError | undefined>(undefined);
  useEffect(() => {
    if (forgotPasswordStatus === ForgotPasswordStatus.changePasswordSuccessful) {
      const email = getItemFromLocalStorage(FORGOT_PASSWORD_STORED_EMAIL);
      if (email) {
        dispatch(loginWithEmailAndPassword({
          email,
          password
        }));
      } else {
        navigate(ROUTES.login);
      }
      // Resetting the state to avoid that the component tries to login the user again
      dispatch(forgotPasswordActions.resetState());
    }
  }, [forgotPasswordStatus, password, dispatch]);
  const handleSubmitForm = useCallback(() => {
    setLocalError(undefined);
    dispatch(forgotPasswordActions.resetState());
    if (password) {
      dispatch(changePasswordWithResetTokenAction({
        userId,
        resetToken,
        password
      }));
    } else {
      setLocalError(new ValidationError(ValidationErrorCode.emptyPassword, VALIDATION_ERROR_MESSAGES[ValidationErrorCode.emptyPassword]));
    }
  }, [password, dispatch, resetToken, userId]);
  return <ChangePasswordFormComponent onPasswordChange={e => setPassword(e.target.value)} onSubmitForm={handleSubmitForm} notification={notification} isLoading={auth.loginStatus === "IN_PROGRESS"} error={localError} data-sentry-element="ChangePasswordFormComponent" data-sentry-component="ChangePasswordForm" data-sentry-source-file="index.tsx" />;
};
export default ChangePasswordForm;