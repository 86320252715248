import React, { useEffect, useState } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { template } from "lodash";
import * as EmailValidator from "email-validator";
import Input from "../input";
import Checkbox from "../Checkbox";
import LinkInternal from "../LinkInternal";
import Button from "../Button";
import { useAppSelector } from "../../state/hooks";
import OnAirNotification from "../OnAirNotification";
import ROUTES from "../../constants/routes";
import { REMEMBER_USER_KEY, storeTokenLocalStorage, storeTokenSessionStorage } from "../../state/tokenHelper";
import { setItemInLocalStorage } from "../../state/localStorage";
import messages from "./messages";
import { LoginReason, SerializedLoginFormDataType } from "./LoginScreenTypes";
import useIsClient from "../../state/useIsClient";
import { RequestStatus } from "./RegisterTypes";
import { NotificationType } from "@/enums/Notification";
import { commonTexts } from "@/messages/common";
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from "@/errors";
import { ButtonType } from "@/enums/ButtonType";
type LoginScreenProps = {
  onSubmitForm: () => void;
  formRef: React.RefObject<HTMLFormElement>;
  registerInput: UseFormRegister<FieldValues>;
  validationErrors: {
    [x: string]: any;
  };
  userCredentials: SerializedLoginFormDataType;
  loginReason: LoginReason;
  onInputChange: (key: keyof SerializedLoginFormDataType, value: string) => void;
  multipassStatus?: RequestStatus;
};
export default ({
  onSubmitForm,
  registerInput,
  validationErrors,
  formRef,
  userCredentials,
  loginReason,
  onInputChange,
  multipassStatus
}: LoginScreenProps) => {
  const auth = useAppSelector(state => state.auth);
  const navigation = useAppSelector(state => state.navigation);
  const [rememberMe, setRememberMe] = useState(true);
  const isClient = useIsClient();
  useEffect(() => {
    if (auth.loginStatus === "LOGGED_IN") {
      // Store user decision about remembering him/her or not
      setItemInLocalStorage(REMEMBER_USER_KEY, rememberMe);
      if (auth.token) {
        const storeTokenFunction = rememberMe ? storeTokenLocalStorage : storeTokenSessionStorage;
        storeTokenFunction(auth.token);
      }
    }
  }, [auth, rememberMe]);
  return <>
			<h5 className="subtitle text-center">{messages.login.title}</h5>
			<p className="body-text text-center mt-2">
				{loginReason === "DEFAULT" && messages.other.login_cta_text}

				{loginReason === "DELETE_ACCOUNT" && <OnAirNotification type={NotificationType.info} text={messages.other.delete_account} className="mb-3" />}

				{loginReason === "CHECKOUT" && <OnAirNotification type={NotificationType.info} text={messages.login.log_in_to_buy_ticket} className="mb-3" />}

				{loginReason === "ERROR" && <OnAirNotification type={NotificationType.error} text={messages.login.error_occurred_log_back_in} className="mb-3" />}
			</p>

			<p className="caption-text text-center mt-2 d-block d-lg-none">
				{messages.login.do_not_have_an_account_yet}{" "}
				<LinkInternal to="/register/" state={isClient ? {
        loginReason: navigation?.loginReason,
        checkoutUrl: navigation?.checkoutUrl
      } : undefined}>
					{messages.login.register_here_link}
				</LinkInternal>
			</p>

			<hr className="mt-5" />

			{auth.loginError?.code && <OnAirNotification className="mt-2" text={auth.loginError?.message} type={NotificationType.error} />}
			<form autoComplete="off" ref={formRef} onSubmit={onSubmitForm} className="m-0">
				<Input onEnter={onSubmitForm} label={commonTexts.email_address} value={userCredentials.email} icon="oa-email" autoGeneratedProps={registerInput("email", {
        required: {
          value: true,
          message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail])({
            field: commonTexts.email_address
          })
        },
        validate: {
          validEmail: (value: string) => EmailValidator.validate(value) || VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail]
        },
        onChange: e => onInputChange("email", e.target.value)
      })} error={validationErrors?.email?.message} testId="loginEmailInput" />

				<Input className="mt-1" onEnter={onSubmitForm} label={commonTexts.password} value={userCredentials.password} type="password" icon="oa-lock" link={{
        to: ROUTES.forgotPassword,
        text: messages.login.forgot_password_question
      }} autoGeneratedProps={registerInput("password", {
        required: {
          value: true,
          message: template(VALIDATION_ERROR_MESSAGES[`${ValidationErrorCode.required}-personal-approach`])({
            field: commonTexts.password
          })
        },
        onChange: e => onInputChange("password", e.target.value)
      })} error={validationErrors?.password?.message} testId="loginPasswordInput" />

				<Checkbox className="mt-2" id="login-remember" label="Remember me" defaultChecked onChange={e => setRememberMe(e.target.checked)} />

				<Button className="mt-3" type={ButtonType.SOLID_PRIMARY} grow onClick={onSubmitForm} loading={auth.loginStatus === "IN_PROGRESS" || multipassStatus === RequestStatus.PENDING} testId="loginSubmitButton">
					{messages.login.submit_button}
				</Button>
			</form>
		</>;
};