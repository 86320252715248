import React from 'react';
import GalleryComponent from './GalleryComponent';
import type { FC } from 'react';
import { ContentfulGallery } from '@/types/contentful';
const Gallery: FC<{
  gallery: ContentfulGallery;
  isFirstBlock: boolean;
}> = ({
  gallery,
  isFirstBlock
}) => <div className="container container--vertical-padding" data-sentry-component="Gallery" data-sentry-source-file="index.tsx">
        <GalleryComponent images={gallery.images} preserveAspectRatio={gallery.preserveAspectRatio} isFirstBlock={isFirstBlock} data-sentry-element="GalleryComponent" data-sentry-source-file="index.tsx" />
    </div>;
export default Gallery;