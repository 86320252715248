import React from 'react';
import type { FC } from 'react';
type YoutubePlayerProps = {
  youtubeId: string;
  autoPlay?: boolean;
};
const YoutubePlayer: FC<YoutubePlayerProps> = ({
  youtubeId,
  autoPlay
}) => <div className="youtube-player" data-sentry-component="YoutubePlayer" data-sentry-source-file="YoutubePlayer.tsx">
        <iframe height="100%" width="100%" src={`https://www.youtube-nocookie.com/embed/${youtubeId}?autoplay=${autoPlay ? '1' : '0'}&rel=0`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded youtube" />
    </div>;
export default YoutubePlayer;