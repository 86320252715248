'use client';

import React, { useCallback, useEffect } from 'react';
import Image from 'next/image';
import messages from './messages';
import HeroButtonAndPrice from './HeroButtonAndPrice';
import HeroEventConfiguratorDate from './HeroEventConfiguratorDate';
import MultipleVariantsConfigurator from './MultipleVariantsConfigurator';
import SingleVariantConfigurator from './SingleVariantConfigurator';
import StickyHeroEventInfo from './StickyHeroEventInfo';
import ROUTES from '../../constants/routes';
import { useAppSelector, useCountryCodeSelector } from '../../state/hooks';
import { navigate } from '../../utils/navigation';
import StickyHero from '../templates/event-page/Stickyhero';
import LinkInternal from '../LinkInternal';
import useIsClient from '../../state/useIsClient';
import { useBuyTicket } from '../../hooks/useBuyTicket';
import { sharedAnalyticsProperties } from '../../utils/analytics';
import type { CartState } from '../../state/reducers/cartSlice';
import type { FC } from 'react';
import { ContentfulEventOption, Event } from '@/types/contentful';
import { useEventConfigurator } from '@/utils/eventConfigurator';
import { trackViewEventDetails } from '@/utils/segment-analytics';
interface HeroEventConfiguratorElementType {
  event: Event;
  releaseDate?: string;
}
const HeroEventConfiguratorElement: FC<HeroEventConfiguratorElementType> = ({
  event,
  releaseDate
}) => {
  const {
    variants,
    slug,
    eventInfoLogo
  } = event;
  const isClient = useIsClient();
  const {
    status: cartStatus
  }: CartState = useAppSelector(state => state.cartSlice);
  const isLoggedIn = useAppSelector(state => state.auth.loginStatus === 'LOGGED_IN');
  const customerCountryCode = useCountryCodeSelector();
  const {
    availableOptions,
    selectedOptions,
    selectedVariant,
    onSelectOption
  } = useEventConfigurator({
    variants
  });
  const {
    addItemToCart
  } = useBuyTicket(event, selectedVariant);
  const handleOnSelectOption = useCallback((option: ContentfulEventOption) => {
    // Get the clicked variant by its option.value prop
    const clickedVariant = variants.find(variant => variant.options?.find(variantOption => variantOption.value === option.value));
    trackViewEventDetails({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      eventDetailsAction: 'Variant Clicked',
      countryCode: customerCountryCode,
      selectedVariant: clickedVariant,
      event
    });
    trackViewEventDetails({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      eventDetailsAction: 'View Event Details',
      countryCode: customerCountryCode,
      selectedVariant: clickedVariant,
      event
    });
    onSelectOption(option);
  }, [customerCountryCode, event, variants, onSelectOption]);
  useEffect(() => {
    if (cartStatus === 'ADD_CART_LINES_SUCCESS' || cartStatus === 'CREATE_CART_SUCCESS') {
      navigate(ROUTES.cart);
    }
  }, [cartStatus]);
  const availableOptionsArray = [...availableOptions];
  const isSingleVariant = variants.length === 1;
  const eventInfoImage = eventInfoLogo!.asset;
  return <>
            {/* Render only if isClient in order to prevent
             "navigator" is not available during server side rendering error. */}
            {isClient && selectedVariant && <StickyHero>
                    <StickyHeroEventInfo eventTitle={event.shortTitle ?? event.title} selectedVariant={selectedVariant} customerCountryCode={customerCountryCode} cartStatus={cartStatus} handleBuyTicketNow={addItemToCart} />
                </StickyHero>}
            <div className="hero__content" key={`hero-event-info-${slug}`}>
                {eventInfoImage && <Image width={(eventInfoImage as any).width} height={(eventInfoImage as any).height} className="hero__event-image" src={(eventInfoImage as any).url} alt={eventInfoLogo!.alt} style={{
        objectFit: 'contain',
        objectPosition: 'left'
      }} />}

                <div className="event-configurator mx-auto">
                    <div className="w-100 d-flex flex-row mt-4 mb-1 justify-content-center justify-content-md-start">
                        <HeroEventConfiguratorDate isSingleVariant={isSingleVariant} releaseDate={releaseDate} data-sentry-element="HeroEventConfiguratorDate" data-sentry-source-file="HeroEventConfiguratorElement.tsx" />

                        {isSingleVariant && <SingleVariantConfigurator variants={variants} />}
                    </div>

                    {!isSingleVariant && <MultipleVariantsConfigurator availableOptionsArray={availableOptionsArray} selectedOptions={selectedOptions} onSelectOption={handleOnSelectOption} />}

                    {selectedVariant && <HeroButtonAndPrice selectedVariant={selectedVariant} handleBuyTicketNow={addItemToCart} isLoading={cartStatus === 'CREATE_CART_PENDING' || cartStatus === 'ADD_CART_LINES_PENDING'} />}
                    {!isLoggedIn && <p className="login-to-see-your-ticket">
                            {messages.alreadyBoughtATicket}
                            <LinkInternal to={ROUTES.login}>
                                {messages.loginToSeeYourTicket}
                            </LinkInternal>
                        </p>}
                </div>
            </div>
        </>;
};
export default HeroEventConfiguratorElement;