'use client';

import React, { useEffect, useRef, useState } from 'react';
import { EventCarouselItem } from './EventCarouselItem';
import { EventCarouselTile } from './EventCarouselTile';
import Carousel from '../carousel';
import type { FC } from 'react';
import { fetchCarouselContent } from '@/utils/data-fetching/fetch/utils';
import { ContentfulEventCarousel, ContentfulEventCarouselContent, Event, ContentfulEventTag } from '@/types/contentful';
interface EventCarouselProps {
  carousel: ContentfulEventCarousel;
  eventTag?: ContentfulEventTag;
  small?: boolean;
  isFirstBlock?: boolean;
}
const EventCarouselComponent: FC<EventCarouselProps> = ({
  carousel,
  eventTag,
  small,
  isFirstBlock
}) => {
  const carouselItemRef = useRef<HTMLAnchorElement>(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const [tiles, setTiles] = useState<ContentfulEventCarouselContent[]>([...new Map([...(carousel?.content ?? []), ...(carousel?.events as ContentfulEventCarouselContent[])].map(object => [object.id, object])).values()]);
  const [page, setPage] = useState(2);
  const TAKE = 10;

  //To be refactored
  const fetchNextPage = async ({
    onLastPage,
    onTheresMore
  }: {
    onLastPage: () => void;
    onTheresMore: () => void;
  }) => {
    if (isLastPage) return;
    const events = await fetchCarouselContent([{
      id: carousel.id ?? '',
      title: carousel.title,
      size: carousel.size ?? 'medium',
      showDates: carousel.showDates
    }], page, TAKE, carousel.category, carousel.slug);
    setTiles([...tiles, ...events[0].events]);
    setPage(prev => prev + 1);
    if (events[0].events?.length === 0) {
      setIsLastPage(true);
      return onLastPage();
    }
    return onTheresMore();
  };
  return <Carousel data={tiles.map((event, counter) => event.__type === 'Tile' ? <EventCarouselTile key={`${eventTag}-${event.id}-${counter}`} tile={event} passedRef={counter === 0 ? carouselItemRef : undefined} lastItem={counter === tiles.length - 1} landscape={carousel.landscape} small={small} /> : <EventCarouselItem key={`${eventTag}-${(event as Event).slug}-${counter}`} event={event as Event} passedRef={counter === 0 ? carouselItemRef : undefined} lastItem={counter === tiles.length - 1} landscape={carousel.landscape} displayDate={carousel.showDates} small={small} title={carousel.title} />)} title={carousel.title} carouselItemRef={carouselItemRef} isLandscape={carousel.landscape} small={small} isFirstBlock={isFirstBlock} fetchNextPage={fetchNextPage} data-sentry-element="Carousel" data-sentry-component="EventCarouselComponent" data-sentry-source-file="EventCarouselComponent.tsx" />;
};
export default EventCarouselComponent;