import React from 'react';
import format from 'date-fns/format';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector, useCountryCodeSelector } from '../../../state/hooks';
import { removeCartLineItemsAction, updateCartLinesAction } from '../../../state/actions/cartActions';
import LoadingSpinner from '../../LoadingSpinner';
import Icon from '../../Icon';
import Button from '../../Button';
import Dropdown from '../../dropdown/Dropdown';
import { createArrayTillN, ensure } from '../../../utils/array';
import { MAX_ADD_ONS_ITEM_QUANTITY } from '../constants';
import { getCartItemCurrency } from '../utils';
import type { CartState } from '../../../state/reducers/cartSlice';
import type { CartEdge, NormalizedCartListItem } from '../../../types/cart';
import type { FC } from 'react';
import { sharedAnalyticsProperties } from '@/utils/analytics';
import { ContentfulEvent } from '@/types/contentful';
import { trackCartAddOrRemove } from '@/utils/segment-analytics';
import { combineCurrencyAndAmount } from '@/utils/i18n';
import { commonTexts } from '@/utils/messages/common';
import { ButtonType } from '@/utils/enum';
type CartListItemProps = NormalizedCartListItem & {
  index: number;
  isLoading: boolean;
};
const CartListItem: FC<CartListItemProps> = ({
  productTitle,
  variantTitle,
  shopifyVariantId,
  poster,
  eventStart,
  quantity,
  price,
  compareAtPrice,
  index,
  lineId,
  estimatedCost,
  isLoading = false,
  isAddOnsProduct,
  icons
}) => {
  const dispatch = useAppDispatch();
  const {
    cart,
    status: cartStatus
  }: CartState = useAppSelector(state => state.cartSlice);
  const customerCountryCode = useCountryCodeSelector();
  const hasDiscount = Number(price) < Number(compareAtPrice);
  const priceCurrency = cart && getCartItemCurrency(cart);
  const removeCartItem = () => {
    const event = {
      shopifyProductId: lineId,
      title: productTitle,
      slug: productTitle
    } as ContentfulEvent;
    dispatch(removeCartLineItemsAction({
      queryVariables: {
        cartId: cart?.id,
        lineIds: [lineId],
        countryCode: customerCountryCode
      }
    }));
    trackCartAddOrRemove({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      cartAction: 'Product Removed',
      event,
      quantity,
      countryCode: customerCountryCode,
      url: window.location.href,
      cartId: `${cart?.id}`
    });
  };
  const handleUpdateQuantity = (newQuantityIndex: number) => {
    const addOnInCart = ensure(cart?.lines.edges.find((edge: CartEdge) => edge.node.id === lineId)).node;

    // Check if the selected quantity is between 1 to 5, to prevent the user from buying more than the established maximum.
    if (cart && newQuantityIndex >= 0 && newQuantityIndex <= MAX_ADD_ONS_ITEM_QUANTITY - 1) {
      const lines = [{
        quantity: newQuantityIndex + 1,
        id: addOnInCart.id,
        attributes: addOnInCart.attributes
      }];
      dispatch(updateCartLinesAction({
        cartId: cart.id,
        lines,
        shopifyVariantId,
        countryCode: customerCountryCode
      }));
    }
  };
  const posterImage = poster.asset.url;
  return <div style={{
    position: 'relative'
  }} data-sentry-component="CartListItem" data-sentry-source-file="CartListItem.tsx">
            {isLoading && <LoadingSpinner className="cart-list-item-loading" />}
            <div className={classNames('row pb-3 ', {
      'dark-row': !(index % 2),
      'remove-cart-pending': isLoading
    })}>
                <div className="event-logo-and-desc col-lg-9 col-md-8 col-sm-12 d-flex">
                    <div className="cart-event-logo">
                        {posterImage && <img src={poster.asset.url} alt={poster.alt} width={136} height={80} className="cart__product-image" />}
                    </div>

                    <div className="cart-item-description">
                        <span className="body-title cart-item-title">
                            {isAddOnsProduct ? variantTitle : productTitle}
                        </span>{' '}
                        {!isAddOnsProduct && <>
                                <br />
                                <span className="caption-text color-accent-light">
                                    {!!icons?.length && icons.map(({
                type
              }) => <Icon key={`ticket-icon-${type}`} icon={type} className="me-1" />)}
                                </span>
                                <br />
                                <span className="caption-text color-accent-light">
                                    {eventStart && format(new Date(eventStart), 'PP HH:mm')}
                                </span>
                                <br />
                            </>}
                        <div className="d-none d-md-flex">
                            <Button type={ButtonType.BUTTON_STYLED_IN_COMPONENT} onClick={removeCartItem} className="cart-item-btn caption-text uppercase-first-letter cart-item-delete-btn" icon="oa-trashcan" disabled={cartStatus === 'REMOVE_CART_LINES_PENDING'} data-sentry-element="Button" data-sentry-source-file="CartListItem.tsx">
                                {commonTexts.remove}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="small-screen-bottom d-flex d-md-none mt-2 ps-2">
                    <Button type={ButtonType.BUTTON_STYLED_IN_COMPONENT} onClick={() => removeCartItem()} className="cart-item-btn caption-text uppercase-first-letter cart-item-delete-btn" icon="oa-trashcan" data-sentry-element="Button" data-sentry-source-file="CartListItem.tsx">
                        {commonTexts.remove}
                    </Button>
                    <span className="body-text cart-item-price quantity">
                        {isAddOnsProduct ? <Dropdown options={createArrayTillN(MAX_ADD_ONS_ITEM_QUANTITY)} selected={quantity - 1} selectCallback={handleUpdateQuantity} smallLabel className="quantity-dropdown" /> : quantity}
                    </span>
                    <div>
                        {compareAtPrice && hasDiscount && !isAddOnsProduct && <span className="body-text me-1 cart-item-price base-price">
                                {combineCurrencyAndAmount(compareAtPrice, priceCurrency)}
                            </span>}
                        <span className="body-text cart-item-price">
                            {combineCurrencyAndAmount(estimatedCost.subtotalAmount?.amount, priceCurrency)}
                        </span>
                    </div>
                </div>
                <div className="cart-item-price centered-cols col-lg-1 col-md-1 col-sm-1 d-none d-md-flex">
                    {compareAtPrice && hasDiscount && <span className="base-price body-text">
                            {combineCurrencyAndAmount(compareAtPrice, priceCurrency)}
                        </span>}
                    <span className="body-text">
                        {combineCurrencyAndAmount(price, priceCurrency)}
                    </span>
                </div>
                <div className="cart-item-quantity text-left centered-cols col-lg-1 col-md-2 col-sm-2 d-none d-md-flex ps-3">
                    {isAddOnsProduct ? <Dropdown options={createArrayTillN(MAX_ADD_ONS_ITEM_QUANTITY)} selected={quantity - 1} selectCallback={handleUpdateQuantity} smallLabel className="quantity-dropdown" /> : <span className="body-text">{quantity}</span>}
                </div>
                <div className="cart-item-total centered-cols col-lg-1 col-md-1 col-sm-1 d-none d-md-flex">
                    <span className="body-text">
                        {combineCurrencyAndAmount(estimatedCost.totalAmount?.amount, priceCurrency)}
                    </span>
                </div>
            </div>
        </div>;
};
export default CartListItem;