'use client';

import React, { useEffect } from 'react';
import FlexiblePageHero from './FlexiblePageHero';
import FlexiblePageBlock from './FlexiblePageBlock';
import Shell from '../../Shell';
import type { EventsByTag, OGP } from '@/types/page-context';
import { logPageViewToSegment } from '@/utils/segment';
import { ContentfulEventCarousel, ContentfulFlexiblePageBlock } from '@/types/contentful';
const FlexiblePage = ({
  hero,
  blocks,
  eventsByTag,
  carousel,
  ogp,
  seo,
  category
}: {
  hero: any;
  blocks: ContentfulFlexiblePageBlock[];
  eventsByTag: EventsByTag;
  carousel?: ContentfulEventCarousel;
  ogp: OGP;
  seo?: any;
  category?: any;
}) => {
  useEffect(() => {
    setTimeout(() => {
      logPageViewToSegment();
    }, 500);
  }, []);
  return <Shell isFooterTopLineDisabled isNewsletterSignupVisible schemaData={{
    carousel
  }} ogp={category === 'venue' ? {
    ...seo,
    slug: '/venue/' + ogp.slug
  } : category === 'genre' ? {
    ...seo,
    slug: '/genre/' + ogp.slug
  } : seo} data-sentry-element="Shell" data-sentry-component="FlexiblePage" data-sentry-source-file="index.tsx">
            {hero && <FlexiblePageHero hero={hero} />}
            {blocks?.map((block, index) => <FlexiblePageBlock key={`block${index}`} isFirstBlock={index === 0 && !!Object.values(hero).length} block={block} eventsByTag={eventsByTag} showTransparentBackground={blocks.length > 1 || index % 2 !== 0} />)}
        </Shell>;
};
export default FlexiblePage;