import React from 'react';
import { AddToCalendarEvent, createAddToCalendarUrl } from '../../../utils/add-to-calendar';
import { sharedAnalyticsProperties } from '../../../utils/analytics';
import { AppleLogo, GoogleLogo, Office365Logo, OutlookLogo, OutlookComLogo, YahooLogo } from '../../svgs';
import { CalendarOptions } from '@/types/calendar';
import { ContentfulEvent } from '@/types/contentful';
import { trackAddToCalendar } from '@/utils/segment-analytics';
interface CalendarOptionProps {
  option: CalendarOptions;
  config: AddToCalendarEvent;
  sku?: string;
}
const optionIcon = (option: CalendarOptions) => {
  switch (option) {
    case 'Apple':
      return <AppleLogo />;
    case 'Google':
      return <GoogleLogo />;
    case 'Office 365':
      return <Office365Logo />;
    case 'Outlook':
      return <OutlookLogo />;
    case 'Outlook.com':
      return <OutlookComLogo />;
    case 'Yahoo':
      return <YahooLogo />;
    default:
      return <></>;
  }
};
export const CalendarOption = ({
  option,
  config,
  sku
}: CalendarOptionProps): JSX.Element => <button type="button" key={option} onClick={() => {
  createAddToCalendarUrl({
    platform: option,
    event: config
  });
  const event = {
    title: config.title,
    slug: sku
  } as ContentfulEvent;
  trackAddToCalendar({
    ...sharedAnalyticsProperties,
    trackCallback: window?.analytics?.track,
    event,
    calendarType: option
  });
}} className="body-text option d-flex m-0 align-items-center" data-sentry-component="CalendarOption" data-sentry-source-file="CalendarOption.tsx">
        <div className="d-flex pe-1 option__icon justify-content-center">
            {optionIcon(option)}
        </div>
        <span>{option}</span>
    </button>;