//@ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import { template } from "lodash";
import { useForm } from "react-hook-form";
import MyPlaceTitle from "./MyPlaceTitle";
import messages from "./messages";
import Input from "../input";
import ROUTES from "../../constants/routes";
import Button from "../Button";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import LinkInternal from "../LinkInternal";
import OnAirNotification from "../OnAirNotification";
import { goToRoute } from "../../utils/navigation";
import type { FC } from "react";
import { serializeForm } from "@/utils/form";
import { isObjectEmpty } from "@/utils/object";
import { verifyPasswordAction, verifyPasswordActions, VerifyPasswordStatus } from "@/reducers/verifyPasswordSlice";
import { changePasswordActions, ChangePasswordStatus } from "@/reducers/changePasswordSlice";
import { changePasswordAction } from "@/actions/profileActions";
import { loginWithEmailAndPassword } from "@/reducers/authSlice";
import { profileActions } from "@/reducers/profileSlice";
import { buildNotification } from "@/utils/notification";
import { profileMessages } from "@/messages/profile";
import { NotificationType } from "@/enums/Notification";
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from "@/errors";
import { ButtonType } from "@/enums/ButtonType";
import { NotificationObject } from "@/types/Notification";
interface ChangePasswordFormData {
  oldPassword?: string;
  newPassword?: string;
}
const MyPlaceProfileSettings: FC<{
  path: string;
}> = () => {
  // Initializing all state, hooks, refs etc
  const {
    register: registerInput,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const [serializedFormData, setSerializedFormData] = useState<ChangePasswordFormData>({});

  // Redux Selectors
  const customerInfo = useAppSelector(state => state.auth.token?.customerInfo);
  const customerAccessToken = useAppSelector(state => state.auth.token?.customerAccessToken);
  const verifyPasswordStatus: VerifyPasswordStatus | undefined = useAppSelector(state => state.verifyPassword.status);
  const changePasswordStatus: ChangePasswordStatus | undefined = useAppSelector(state => state.changePassword.status);
  const verifyPasswordNotification: NotificationObject | undefined = useAppSelector(state => state.verifyPassword.notification);
  const changePasswordNotification: NotificationObject | undefined = useAppSelector(state => state.changePassword.notification);

  // Handling form related stuff, changing and submitting
  const handleChangeForm = useCallback(() => {
    if (formRef.current) {
      const formElement: HTMLFormElement = formRef.current;
      const serializedData = serializeForm(formElement);
      setSerializedFormData({
        ...serializedData
      });
    }
  }, [setSerializedFormData]);
  const handleSubmitForm = useCallback(() => {
    if (isObjectEmpty(errors) && !isObjectEmpty(serializedFormData) && customerInfo) {
      const {
        oldPassword
      } = serializedFormData;
      dispatch(verifyPasswordAction({
        email: customerInfo.email,
        password: oldPassword ?? ""
      }));
    }
  }, [errors, serializedFormData, dispatch, customerInfo]);

  // Effects
  // Clear page when leaving to prevent bugs when navigating back to it
  useEffect(() => () => {
    dispatch(changePasswordActions.resetState());
    dispatch(verifyPasswordActions.resetState());
  }, [dispatch]);

  // When password verification is successful, dispatch changePasswordAction
  useEffect(() => {
    if (verifyPasswordStatus === "VERIFY_PASSWORD_SUCCESSFUL" && customerAccessToken) {
      dispatch(changePasswordAction({
        customerAccessToken,
        customer: {
          password: serializedFormData.newPassword
        }
      }));
    }
  }, [dispatch, verifyPasswordStatus, customerAccessToken, serializedFormData]);

  // When changePasswordAction is successful, navigate away and build notification for on my-profile page
  useEffect(() => {
    if (changePasswordStatus === "CHANGE_PASSWORD_SUCCESSFUL" && customerInfo) {
      goToRoute(`${ROUTES.myPlace}${ROUTES.profileSettings}`);

      // Login again because all old customerAccessTokens have been invalidated
      dispatch(loginWithEmailAndPassword({
        email: customerInfo.email,
        password: serializedFormData.newPassword ?? "",
        silentRelogin: true
      }));
      dispatch(profileActions.updateNotification(buildNotification(profileMessages.success_updating_profile, NotificationType.success)));
    }
  }, [dispatch, changePasswordStatus, customerInfo, serializedFormData]);
  return <>
			<MyPlaceTitle testId="my-place-profile-settings" data-sentry-element="MyPlaceTitle" data-sentry-source-file="MyPlaceChangePassword.tsx">
				{messages.profile_settings.change_password.change_password}
			</MyPlaceTitle>

			{verifyPasswordNotification?.text && <OnAirNotification className="mb-3" text={verifyPasswordNotification.text} type={verifyPasswordNotification.type} />}
			{changePasswordNotification?.text && <OnAirNotification className="mb-3" text={changePasswordNotification.text} type={changePasswordNotification.type} />}

			<form autoComplete="off" onChange={handleChangeForm} onSubmit={handleSubmit(handleSubmitForm)} className="m-0" ref={formRef}>
				<Input value={customerInfo?.email} type="hidden" data-sentry-element="Input" data-sentry-source-file="MyPlaceChangePassword.tsx" />
				<Input icon="oa-lock" label={messages.profile_settings.change_password.current_password} type="password" autoComplete="password" defaultValue="" error={errors.oldPassword?.message} autoGeneratedProps={registerInput("oldPassword", {
        required: {
          value: true,
          message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
            field: messages.profile_settings.change_password.current_password.toLowerCase()
          })
        }
      })} data-sentry-element="Input" data-sentry-source-file="MyPlaceChangePassword.tsx" />
				<Input className="mt-2" icon="oa-lock" label={messages.profile_settings.change_password.new_password} type="password" autoComplete="new-password" defaultValue="" error={errors.newPassword?.message} autoGeneratedProps={registerInput("newPassword", {
        required: {
          value: true,
          message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
            field: messages.profile_settings.change_password.new_password.toLowerCase()
          })
        },
        minLength: {
          value: 5,
          message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.notLongEnough])({
            field: messages.profile_settings.change_password.new_password.toLowerCase(),
            amount: 5
          })
        },
        validate: {
          doesNotMatchOldPassword: v => v !== serializedFormData.oldPassword || VALIDATION_ERROR_MESSAGES[ValidationErrorCode.matchesOldPassword]
        }
      })} data-sentry-element="Input" data-sentry-source-file="MyPlaceChangePassword.tsx" />

				<Button className="mt-4" grow onClick={handleSubmit(handleSubmitForm)} loading={verifyPasswordStatus === "VERIFY_PASSWORD_PENDING" || changePasswordStatus === "CHANGE_PASSWORD_PENDING"} data-sentry-element="Button" data-sentry-source-file="MyPlaceChangePassword.tsx">
					{messages.profile_settings.change_password.change_password}
				</Button>
				<LinkInternal to={`${ROUTES.myPlace}${ROUTES.profileSettings}`} data-sentry-element="LinkInternal" data-sentry-source-file="MyPlaceChangePassword.tsx">
					<Button type={ButtonType.OUTLINE_SECONDARY} className="mt-2" grow data-sentry-element="Button" data-sentry-source-file="MyPlaceChangePassword.tsx">
						{messages.profile_settings.change_password.cancel}
					</Button>
				</LinkInternal>
			</form>
		</>;
};
export default MyPlaceProfileSettings;