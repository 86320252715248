import classNames from 'classnames';
import { ButtonType, mapContentfulButtonTypeToEnum } from '@/enums/ButtonType';
import { ContentfulHero } from '@/types/contentful';
import Button from '../Button';
import HeroComponent from './HeroComponent';
import HeroMetaContent from './HeroMetaContent';
import HeroSubtitle from './HeroSubtitle';
import HeroTitle from './HeroTitle';
import type { FC } from 'react';
const Hero: FC<{
  hero: ContentfulHero;
}> = ({
  hero
}) => {
  const {
    background,
    video,
    title,
    subtitle,
    meta,
    layout,
    button
  } = hero;
  const backgroundImageProps = {
    imageUrl: (background?.asset as any)?.url as string,
    alt: background?.alt
  };
  const thumbnailUrl = video?.thumbnailImage?.asset?.url ? video.thumbnailImage.asset.url : backgroundImageProps.imageUrl;
  const videoProps = video?.hlsUrl ? {
    videoUrl: video.hlsUrl,
    thumbnailUrl: thumbnailUrl
  } : undefined;
  return <HeroComponent backgroundImageProps={backgroundImageProps} videoProps={videoProps} colClassNames="" layout={layout} data-sentry-element="HeroComponent" data-sentry-component="Hero" data-sentry-source-file="index.tsx">
            <div className={classNames('hero__content', {
      'hero__content--center': layout === 'center',
      'hero__content--left': layout === 'left',
      'hero__content--right': layout === 'right'
    })}>
                <HeroTitle data-sentry-element="HeroTitle" data-sentry-source-file="index.tsx">{title}</HeroTitle>
                <HeroSubtitle data-sentry-element="HeroSubtitle" data-sentry-source-file="index.tsx">{subtitle}</HeroSubtitle>
                <HeroMetaContent {...meta} data-sentry-element="HeroMetaContent" data-sentry-source-file="index.tsx" />
                {button?.text && <div className="d-flex mt-4">
                        <Button className="hero__generic-button" type={button.type ? mapContentfulButtonTypeToEnum[button.type] : ButtonType.SOLID_SECONDARY} hrefInternal={button.href}>
                            {button.text}
                        </Button>
                    </div>}
            </div>
        </HeroComponent>;
};
export default Hero;