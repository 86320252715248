import React, { FC } from 'react';
import EventSellingPointComponent from './SellingPoint';
import { renderContentfulDescription } from '../../utils/contentful';
import classNames from 'classnames';
import { ContentfulSellingPoint } from '@/types/contentful';
export type SellingPointsSectionProps = {
  title?: string;
  sellingPoints: ContentfulSellingPoint[];
  showTitle: boolean;
  isFirstBlock?: boolean;
};
const SellingPointsSectionComponent: FC<SellingPointsSectionProps> = ({
  title,
  sellingPoints,
  showTitle,
  isFirstBlock
}) => <div className={classNames('container container--vertical-padding', {
  'first-block': isFirstBlock
})} data-sentry-component="SellingPointsSectionComponent" data-sentry-source-file="SellingPointsSectionComponent.tsx">
        {showTitle && title && <h3 className="title mb-3">{title}</h3>}
        <div className="event-selling-points-wrap">
            {sellingPoints.map(item => <EventSellingPointComponent key={item.title} title={item.title} description={renderContentfulDescription(item.description)} icon={item?.icon?.type} />)}
        </div>
    </div>;
export default SellingPointsSectionComponent;