'use client';

import React from 'react';
import ContentBlockComponent from '../content-block/ContentBlockComponent';
import { renderContentfulDescription } from '../../utils/contentful';
import { useContentfulButtonProps } from '../../hooks/useContentfulButtonProps';
import { ContentfulContentBlock, ContentfulIcon, ContentfulImage } from '@/types/contentful';
const EventContentBlock = ({
  event,
  title,
  subtitle,
  description,
  image,
  button,
  imagePosition,
  links,
  showAppStoreBanners,
  socialMediaIconsTitle,
  icons,
  type,
  isFirstBlock
}: ContentfulContentBlock & {
  icons?: ContentfulIcon[];
  isFirstBlock?: boolean;
}) => {
  const contentfulButtonProps = useContentfulButtonProps(button);
  return <ContentBlockComponent event={event} isFirstBlock={isFirstBlock} title={title} subtitle={subtitle} description={renderContentfulDescription(description)} image={image as ContentfulImage} buttonProps={contentfulButtonProps} icons={icons} showAppStoreBanners={showAppStoreBanners} imagePosition={imagePosition} links={links} useH1TagForTitle={type === 'EventDescription'} socialMediaIconsTitle={socialMediaIconsTitle} data-sentry-element="ContentBlockComponent" data-sentry-component="EventContentBlock" data-sentry-source-file="index.tsx" />;
};
export default EventContentBlock;