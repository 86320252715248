import React, { FC, ReactNode } from 'react';
import Icon from '../Icon';
export type EventSellingPointProps = {
  readonly title: string;
  readonly description: ReactNode;
  readonly icon: string;
};
const SellingPoint: FC<EventSellingPointProps> = ({
  title,
  description,
  icon
}) => <div className="event-selling-point container text-center p-2 p-md-3 pt-md-5" data-sentry-component="SellingPoint" data-sentry-source-file="SellingPoint.tsx">
        <div className="pb-2 pt-0 pt-sm-2 pb-md-5">
            <Icon icon={icon} data-sentry-element="Icon" data-sentry-source-file="SellingPoint.tsx" />
        </div>
        <h5 className="section-title">{title}</h5>
        <div className="event-selling-point__description body-text mt-2 mt-md-3">
            {description}
        </div>
    </div>;
export default SellingPoint;