'use client';

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Event, EventStatus, EventVariant } from '@/types/contentful';
import { useSearchParams } from 'next/navigation';
import { useAppSelector } from '../../../state/hooks';
import EventPageComponent from './EventPageComponent';
import { THEOplayerError } from '../../theo-player/types';
import { useEventConfiguratorProperties } from './useEventConfiguratorProperties';
import { useEventPageStatus } from './useEventPageStatus';
import { useStreamUrlsCheck } from './useStreamUrlsCheck';
import { sharedAnalyticsProperties } from '../../../utils/analytics';
import { useTheoPlayerContext } from '../../theo-player/theo-player-context';
import { getItemFromLocalStorage } from '@/state/localStorage';
import { EventsByTag } from '@/types/page-context';
import { trackPlayerEvent, trackProduct } from '@/utils/segment-analytics';
import { PlayerEventAction } from '@/types/Segment';
type EventPageProps = {
  readonly location: Location;
  readonly event: Event;
  readonly eventsByTag: EventsByTag;
};
export const EventPage = ({
  event,
  eventsByTag
}: EventPageProps) => {
  const searchParams = useSearchParams();
  const isWatchTrailerParamSet = searchParams?.has('watch-trailer');
  const [selectedVariant, setSelectedVariant] = useState<EventVariant>();
  const [eventStatus, setEventStatus] = useState<EventStatus>();
  const jwt = useAppSelector(state => state.auth.token?.jwt);
  const [playerError, setPlayerError] = useState<THEOplayerError | undefined>();
  const shopifyUserId = useAppSelector(state => state.auth.token?.customerInfo?.id);
  const {
    hasTicketInShopify,
    loggedIn,
    doorsOpenDate
  } = useEventPageStatus(event, setSelectedVariant, setEventStatus, selectedVariant, eventStatus);
  const {
    isShowRestrictedInCurrentCountry,
    partnerToAccessShowInCurrentCountry,
    stream,
    backgroundImageProps,
    countDownBannerInfo,
    willShowGoLive,
    isSpoLiveShowWithOpenSale,
    endSaleDate,
    isSaleOpen,
    schemaData,
    eventEndDate
  } = useEventConfiguratorProperties(event, selectedVariant!, eventStatus!);
  const onPlayerError = useCallback((error: THEOplayerError) => setPlayerError(error), [setPlayerError]);
  const {
    isTheoLoading
  } = useTheoPlayerContext();
  const error = playerError;
  const isLive = useMemo(() => eventStatus === 'LIVE', [eventStatus]);

  // hasAccess is only true when the user has the ticket and the event is LIVE
  const hasAccess = isLive && hasTicketInShopify;
  const showPlayer = isLive && (!!selectedVariant?.stream?.dashUrl || !!selectedVariant?.stream?.hlsUrl) && hasAccess && !error;
  const showCountdownBanner = (willShowGoLive || isSpoLiveShowWithOpenSale) && (!loggedIn || loggedIn && !hasTicketInShopify) && endSaleDate && !hasAccess && !error;
  const showEventConfigurator = !hasTicketInShopify && !isShowRestrictedInCurrentCountry && !hasAccess && (eventStatus === 'FUTURE' || isLive && isSaleOpen);
  const showPlayerLoader = isTheoLoading || !error && hasAccess && !showPlayer;

  // When you the Event Page is loaded by an external navigation then the
  // configurator is not shown because the eventStatus is undefined
  const showPlaceholder = !eventStatus && !showPlayer && !showPlayerLoader && !showCountdownBanner && !showEventConfigurator;
  const eventStart = selectedVariant?.eventStart ? new Date(selectedVariant.eventStart) : undefined;
  const duration = event?.variants?.[0]?.stream?.duration;
  const eventSEO = {
    title: event?.seo?.title ?? event?.title!,
    description: event?.seo?.description.description ?? event.shortDescription!,
    slug: event.slug,
    imageUrl: event.seo?.image?.asset?.file?.url ?? event.poster?.asset?.file?.url
  };
  const handleSegmentTrackPlay = useCallback((_duration: number) => {
    // Send 'Play Event' track event to Segment
    trackPlayerEvent({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      playerEventAction: 'Play Event',
      event,
      selectedVariant,
      duration: _duration
    });
  }, [event, selectedVariant]);
  const handleSegmentTrackPlayerEvents = useCallback((trackAction: PlayerEventAction) => {
    trackPlayerEvent({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      playerEventAction: trackAction,
      event,
      selectedVariant
    });
  }, [event, selectedVariant]);
  const futureEvent = hasTicketInShopify && loggedIn && eventStart! > new Date();
  const premieredEvent = hasTicketInShopify && loggedIn && eventStart! < new Date() && eventEndDate! > new Date();
  const pastEvent = hasTicketInShopify && loggedIn && eventStart! < new Date() && eventEndDate! < new Date();
  const purchased = useMemo(() => futureEvent || premieredEvent || pastEvent, [eventStart]);
  const available = useMemo(
  //@ts-ignore
  () => (endSaleDate || eventEndDate) > new Date(), [endSaleDate, eventEndDate]);
  useEffect(() => {
    if (selectedVariant) {
      trackProduct({
        ...sharedAnalyticsProperties,
        trackCallback: window?.analytics?.track,
        action: 'Product Viewed',
        url: window.location.href,
        event,
        purchased,
        available,
        carouselTitle: getItemFromLocalStorage('carouselTitle')
      });
    }
  }, [event, selectedVariant]);
  const streamUrlsError = useStreamUrlsCheck({
    isLive,
    hasAccess,
    stream
  });
  return <EventPageComponent event={event} eventsByTag={eventsByTag} isShowRestrictedInCurrentCountry={isShowRestrictedInCurrentCountry} partnerToAccessShowInCurrentCountry={partnerToAccessShowInCurrentCountry} isWatchTrailerParamSet={isWatchTrailerParamSet} loggedIn={loggedIn} blocks={event.blocks} showCountdownBanner={showCountdownBanner} countDownBannerInfo={countDownBannerInfo} showPlayer={showPlayer} showEventConfigurator={showEventConfigurator} showPlayerLoader={showPlayerLoader} showError={!!error} onPlayerError={onPlayerError} error={error} showPlaceholder={showPlaceholder} hasAccess={hasAccess} hasTicketInShopify={hasTicketInShopify} isSaleOpen={isSaleOpen} eventStatus={eventStatus} doorsOpenDate={doorsOpenDate} stream={stream} duration={duration} eventStart={eventStart} selectedVariantSku={selectedVariant?.sku} ogp={eventSEO} schemaData={schemaData} backgroundImageProps={backgroundImageProps} trackingTitle={selectedVariant?.sku} shopifyUserId={shopifyUserId} logTrackPlayEvent={handleSegmentTrackPlay} trackPlayerEvents={handleSegmentTrackPlayerEvents} streamUrlsError={streamUrlsError} jwt={jwt} data-sentry-element="EventPageComponent" data-sentry-component="EventPage" data-sentry-source-file="EventPage.tsx" />;
};