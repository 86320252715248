"use client";

import React, { FC, ReactNode, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import classNames from 'classnames';
import { userAgentDetails } from '../../../utils/user-agent';
const StickyHero: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const stickyComp = useRef(null);
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const timeline = gsap.timeline({
      scrollTrigger: {
        start: '0',
        trigger: '.event-configurator__buy-button-wrapper',
        toggleActions: 'play none none reverse'
      }
    });

    /**
     * When the buy button reaches the top of the screen we are triggering the animation.
     * if it on desktop we are animating the banner from top and we are stoping just below the header.
     * if it is mobile we are animating from the bottom of the screen until the banner is visible on the bottom of screen
     */
    timeline.to(stickyComp.current, {
      y: !userAgentDetails().isMobile ? 22 : undefined,
      bottom: userAgentDetails().isMobile ? 0 : undefined,
      duration: 0.5
    });
  }, []);
  return <div className={classNames('sticky-event-configurator', {
    'sticky-mobile': userAgentDetails().isMobile
  })} ref={stickyComp} data-sentry-component="StickyHero" data-sentry-source-file="Stickyhero.tsx">
            <>{children}</>
        </div>;
};
export default StickyHero;