import React, { FC } from 'react';
import classNames from 'classnames';
import { HighlightComponent } from './HighlightComponent';
import { renderContentfulDescription } from '../../utils/contentful';
import { ContentfulHighlight } from '@/types/contentful';
export interface HighlightsBlockProps {
  readonly highlights: ContentfulHighlight[];
  readonly showTransparentBackground?: boolean;
  readonly isFirstBlock?: boolean;
}
export const HighlightsBlock: FC<HighlightsBlockProps> = ({
  highlights,
  showTransparentBackground,
  isFirstBlock
}) => <section className={classNames('highlights-block', {
  'highlights-block--show-transparent-background': showTransparentBackground,
  'first-block': isFirstBlock
})} data-sentry-component="HighlightsBlock" data-sentry-source-file="index.tsx">
        <div className="container">
            {highlights.map((highlight, counter) => <HighlightComponent key={`${highlight.id}-${counter}`} title={highlight.title} text={renderContentfulDescription(highlight.text)} icon={highlight?.icon?.type} />)}
        </div>
    </section>;