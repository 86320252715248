import React, { FC, ReactNode } from 'react';
import { SkeletonTheme as Theme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export type SkeletonThemeProps = {
  children: ReactNode;
};
const SkeletonTheme: FC<SkeletonThemeProps> = ({
  children
}) => <>
        <Theme baseColor="#343336" // shade-5
  highlightColor="#bcb8bf" // shade-3
  borderRadius="4px" duration={3} data-sentry-element="Theme" data-sentry-source-file="SkeletonTheme.tsx">
            {children}
        </Theme>
    </>;
export default SkeletonTheme;